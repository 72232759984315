const onMarketData = [
    {
      "neighborhood": "Overbrook",
      "onMarketRate": 5.126299746588869,
      "count": 2482
    },
    {
      "neighborhood": "Southwest Germantown",
      "onMarketRate": 3.478214064644615,
      "count": 767
    },
    {
      "neighborhood": "East Parkside",
      "onMarketRate": 6.319933427218027,
      "count": 668
    },
    {
      "neighborhood": "Germany Hill",
      "onMarketRate": 10.752506518785118,
      "count": 681
    },
    {
      "neighborhood": "East Mount Airy",
      "onMarketRate": 4.984702736969765,
      "count": 1461
    },
    {
      "neighborhood": "Dearnley Park",
      "onMarketRate": 1.2860596293311843,
      "count": 276
    },
    {
      "neighborhood": "Wissinoming",
      "onMarketRate": 2.152191063616152,
      "count": 1452
    },
    {
      "neighborhood": "Bella Vista",
      "onMarketRate": 2.916560449733673,
      "count": 1912
    },
    {
      "neighborhood": "Allegheny West",
      "onMarketRate": 7.245713606513533,
      "count": 1317
    },
    {
      "neighborhood": "Glenwood",
      "onMarketRate": 4.968866749688666,
      "count": 323
    },
    {
      "neighborhood": "Greenwich",
      "onMarketRate": 3.6335373554843096,
      "count": 448
    },
    {
      "neighborhood": "Francisville",
      "onMarketRate": 4.349285417778569,
      "count": 4206
    },
    {
      "neighborhood": "Penrose",
      "onMarketRate": 2.2210045662100457,
      "count": 128
    },
    {
      "neighborhood": "Powelton",
      "onMarketRate": 6.192515939324545,
      "count": 2693
    },
    {
      "neighborhood": "Garden Court",
      "onMarketRate": 7.147766323024062,
      "count": 979
    },
    {
      "neighborhood": "Belmont",
      "onMarketRate": 9.11082156565835,
      "count": 557
    },
    {
      "neighborhood": "Pennsport",
      "onMarketRate": 2.7688233563278506,
      "count": 1527
    },
    {
      "neighborhood": "Stanton",
      "onMarketRate": 5.6054695553924425,
      "count": 2993
    },
    {
      "neighborhood": "West Oak Lane",
      "onMarketRate": 4.144525144800957,
      "count": 1195
    },
    {
      "neighborhood": "Burholme",
      "onMarketRate": 4.372887386586016,
      "count": 113
    },
    {
      "neighborhood": "Fern Rock",
      "onMarketRate": 10.796125408379666,
      "count": 323
    },
    {
      "neighborhood": "Dunlap",
      "onMarketRate": 5.4089143324473525,
      "count": 423
    },
    {
      "neighborhood": "Melrose Park Gardens",
      "onMarketRate": 1.018463371054199,
      "count": 118
    },
    {
      "neighborhood": "Franklinville",
      "onMarketRate": 4.43426702105909,
      "count": 511
    },
    {
      "neighborhood": "Hawthorne",
      "onMarketRate": 3.294873141382465,
      "count": 1763
    },
    {
      "neighborhood": "Bartram Village",
      "onMarketRate": 5.7486181206440765,
      "count": 85
    },
    {
      "neighborhood": "East Poplar",
      "onMarketRate": 4.623633596236334,
      "count": 735
    },
    {
      "neighborhood": "Bustleton",
      "onMarketRate": 3.263687099303537,
      "count": 888
    },
    {
      "neighborhood": "Cedarbrook",
      "onMarketRate": 4.6075143522992095,
      "count": 312
    },
    {
      "neighborhood": "Ludlow",
      "onMarketRate": 8.748858447488592,
      "count": 1728
    },
    {
      "neighborhood": "Bridesburg",
      "onMarketRate": 2.2541706225417064,
      "count": 135
    },
    {
      "neighborhood": "East Falls",
      "onMarketRate": 2.6309376686219577,
      "count": 2777
    },
    {
      "neighborhood": "North Central",
      "onMarketRate": 9.808581668508346,
      "count": 10336
    },
    {
      "neighborhood": "Grays Ferry",
      "onMarketRate": 5.952660642105943,
      "count": 2492
    },
    {
      "neighborhood": "Frankford",
      "onMarketRate": 3.5639496494114926,
      "count": 2008
    },
    {
      "neighborhood": "Chestnut Hill",
      "onMarketRate": 3.2887715244539795,
      "count": 1576
    },
    {
      "neighborhood": "Olney",
      "onMarketRate": 2.8397477712546233,
      "count": 1159
    },
    {
      "neighborhood": "West Mount Airy",
      "onMarketRate": 5.9652509652509655,
      "count": 1529
    },
    {
      "neighborhood": "Somerton",
      "onMarketRate": 1.3502133393218054,
      "count": 687
    },
    {
      "neighborhood": "Mantua",
      "onMarketRate": 9.220040642680662,
      "count": 4133
    },
    {
      "neighborhood": "Modena",
      "onMarketRate": 3.9207527845346317,
      "count": 704
    },
    {
      "neighborhood": "Roxborough Park",
      "onMarketRate": 3.8688252386882525,
      "count": 176
    },
    {
      "neighborhood": "Morrell Park",
      "onMarketRate": 4.954945570081778,
      "count": 817
    },
    {
      "neighborhood": "Riverfront",
      "onMarketRate": 1.502885081266793,
      "count": 409
    },
    {
      "neighborhood": "Pennypack",
      "onMarketRate": 2.4623287671232874,
      "count": 664
    },
    {
      "neighborhood": "Yorktown",
      "onMarketRate": 2.9837016298370163,
      "count": 261
    },
    {
      "neighborhood": "Parkwood Manor",
      "onMarketRate": 1.9090953095503174,
      "count": 398
    },
    {
      "neighborhood": "Aston-Woodbridge",
      "onMarketRate": 2.4910786232301136,
      "count": 159
    },
    {
      "neighborhood": "Pennypack Woods",
      "onMarketRate": 0.5955926146515784,
      "count": 57
    },
    {
      "neighborhood": "Millbrook",
      "onMarketRate": 2.0801623541349574,
      "count": 70
    },
    {
      "neighborhood": "Academy Gardens",
      "onMarketRate": 4.607212748112943,
      "count": 146
    },
    {
      "neighborhood": "Torresdale",
      "onMarketRate": 1.2073195665508076,
      "count": 381
    },
    {
      "neighborhood": "Lexington Park",
      "onMarketRate": 1.379310344827586,
      "count": 122
    },
    {
      "neighborhood": "Rhawnhurst",
      "onMarketRate": 2.232441066847378,
      "count": 1188
    },
    {
      "neighborhood": "Fox Chase",
      "onMarketRate": 3.75283334975855,
      "count": 597
    },
    {
      "neighborhood": "Oxford Circle",
      "onMarketRate": 1.375081539465101,
      "count": 1527
    },
    {
      "neighborhood": "Summerdale",
      "onMarketRate": 2.6524040823009325,
      "count": 359
    },
    {
      "neighborhood": "Lawndale",
      "onMarketRate": 3.418044402456304,
      "count": 799
    },
    {
      "neighborhood": "Sharswood",
      "onMarketRate": 8.667496886674972,
      "count": 661
    },
    {
      "neighborhood": "Passyunk Square",
      "onMarketRate": 2.3932993315353333,
      "count": 2559
    },
    {
      "neighborhood": "Northwood",
      "onMarketRate": 4.619111259605746,
      "count": 222
    },
    {
      "neighborhood": "Holmesburg",
      "onMarketRate": 1.917808219178082,
      "count": 1335
    },
    {
      "neighborhood": "Mayfair",
      "onMarketRate": 1.4343271555197419,
      "count": 1866
    },
    {
      "neighborhood": "Tacony",
      "onMarketRate": 3.7530266343825684,
      "count": 1177
    },
    {
      "neighborhood": "Hartranft",
      "onMarketRate": 7.794221725933024,
      "count": 3041
    },
    {
      "neighborhood": "Andorra",
      "onMarketRate": 3.1456113647894473,
      "count": 80
    },
    {
      "neighborhood": "Manayunk",
      "onMarketRate": 2.989394608926202,
      "count": 5641
    },
    {
      "neighborhood": "Upper Roxborough",
      "onMarketRate": 3.3498056029940773,
      "count": 1358
    },
    {
      "neighborhood": "Roxborough",
      "onMarketRate": 2.36650177574835,
      "count": 3415
    },
    {
      "neighborhood": "Nicetown",
      "onMarketRate": 2.2778864970645794,
      "count": 214
    },
    {
      "neighborhood": "Wissahickon",
      "onMarketRate": 2.095875472430947,
      "count": 1948
    },
    {
      "neighborhood": "Dickinson Narrows",
      "onMarketRate": 2.9078192321811547,
      "count": 1230
    },
    {
      "neighborhood": "East Germantown",
      "onMarketRate": 4.101177601538093,
      "count": 1022
    },
    {
      "neighborhood": "Germantown - Morton",
      "onMarketRate": 5.382119682768565,
      "count": 569
    },
    {
      "neighborhood": "Old Kensington",
      "onMarketRate": 5.604553699881152,
      "count": 2807
    },
    {
      "neighborhood": "West Central Germantown",
      "onMarketRate": 5.50669452828729,
      "count": 1804
    },
    {
      "neighborhood": "Germantown - Penn Knox",
      "onMarketRate": 2.7116262732701086,
      "count": 236
    },
    {
      "neighborhood": "Germantown - Westside",
      "onMarketRate": 5.432723082831906,
      "count": 630
    },
    {
      "neighborhood": "Ogontz",
      "onMarketRate": 3.657316808001741,
      "count": 1138
    },
    {
      "neighborhood": "Wister",
      "onMarketRate": 2.9942024524139335,
      "count": 422
    },
    {
      "neighborhood": "Tioga",
      "onMarketRate": 6.420698886452306,
      "count": 1482
    },
    {
      "neighborhood": "East Oak Lane",
      "onMarketRate": 5.77408488659331,
      "count": 408
    },
    {
      "neighborhood": "Feltonville",
      "onMarketRate": 3.3403944828884815,
      "count": 448
    },
    {
      "neighborhood": "Fairhill",
      "onMarketRate": 3.190567019247443,
      "count": 115
    },
    {
      "neighborhood": "Richmond",
      "onMarketRate": 4.882488651596831,
      "count": 3797
    },
    {
      "neighborhood": "Hunting Park",
      "onMarketRate": 3.4880038007759917,
      "count": 503
    },
    {
      "neighborhood": "Juniata Park",
      "onMarketRate": 3.318112633181126,
      "count": 332
    },
    {
      "neighborhood": "Brewerytown",
      "onMarketRate": 5.04046857633798,
      "count": 3172
    },
    {
      "neighborhood": "Harrowgate",
      "onMarketRate": 6.45586569162633,
      "count": 1537
    },
    {
      "neighborhood": "Upper Kensington",
      "onMarketRate": 6.8429436126154775,
      "count": 1286
    },
    {
      "neighborhood": "McGuire",
      "onMarketRate": 3.315376327535786,
      "count": 113
    },
    {
      "neighborhood": "West Kensington",
      "onMarketRate": 8.551835550500307,
      "count": 2409
    },
    {
      "neighborhood": "Fishtown - Lower Kensington",
      "onMarketRate": 4.573798285482315,
      "count": 6386
    },
    {
      "neighborhood": "Northern Liberties",
      "onMarketRate": 5.364716739858989,
      "count": 6117
    },
    {
      "neighborhood": "Logan",
      "onMarketRate": 4.6007536792789105,
      "count": 1617
    },
    {
      "neighborhood": "Society Hill",
      "onMarketRate": 1.7020139806171066,
      "count": 3364
    },
    {
      "neighborhood": "Old City",
      "onMarketRate": 3.7564195576268125,
      "count": 6875
    },
    {
      "neighborhood": "Chinatown",
      "onMarketRate": 2.9269406392694055,
      "count": 407
    },
    {
      "neighborhood": "Center City East",
      "onMarketRate": 4.229161829579754,
      "count": 462
    },
    {
      "neighborhood": "Washington Square West",
      "onMarketRate": 3.9511624133694894,
      "count": 9152
    },
    {
      "neighborhood": "Fairmount",
      "onMarketRate": 3.539473577745596,
      "count": 3414
    },
    {
      "neighborhood": "Spring Garden",
      "onMarketRate": 7.800625702396496,
      "count": 5209
    },
    {
      "neighborhood": "Logan Square",
      "onMarketRate": 6.790097605954072,
      "count": 13666
    },
    {
      "neighborhood": "Rittenhouse",
      "onMarketRate": 4.418445779614192,
      "count": 20674
    },
    {
      "neighborhood": "Fitler Square",
      "onMarketRate": 2.3760155001869547,
      "count": 874
    },
    {
      "neighborhood": "Graduate Hospital",
      "onMarketRate": 3.175007633748538,
      "count": 7895
    },
    {
      "neighborhood": "Point Breeze",
      "onMarketRate": 5.618074270511351,
      "count": 5665
    },
    {
      "neighborhood": "Queen Village",
      "onMarketRate": 3.2230676337450106,
      "count": 3470
    },
    {
      "neighborhood": "Lower Moyamensing",
      "onMarketRate": 2.558773840297991,
      "count": 2148
    },
    {
      "neighborhood": "Whitman",
      "onMarketRate": 2.9696293936497833,
      "count": 839
    },
    {
      "neighborhood": "Southwest Schuylkill",
      "onMarketRate": 5.142629517339056,
      "count": 993
    },
    {
      "neighborhood": "Clearview",
      "onMarketRate": 2.912447885646218,
      "count": 64
    },
    {
      "neighborhood": "West Parkside",
      "onMarketRate": 1.3877307921381776,
      "count": 64
    },
    {
      "neighborhood": "Mill Creek",
      "onMarketRate": 4.621314139772462,
      "count": 947
    },
    {
      "neighborhood": "Paschall",
      "onMarketRate": 3.8874490929285446,
      "count": 724
    },
    {
      "neighborhood": "Spruce Hill",
      "onMarketRate": 4.077781600184977,
      "count": 4465
    },
    {
      "neighborhood": "West Powelton",
      "onMarketRate": 6.022261508528313,
      "count": 2952
    },
    {
      "neighborhood": "Kingsessing",
      "onMarketRate": 4.1788694693749,
      "count": 2136
    },
    {
      "neighborhood": "University City",
      "onMarketRate": 6.1662987490801955,
      "count": 5960
    },
    {
      "neighborhood": "Haddington",
      "onMarketRate": 4.307262692671343,
      "count": 1905
    },
    {
      "neighborhood": "Carroll Park",
      "onMarketRate": 4.410540915395283,
      "count": 1045
    },
    {
      "neighborhood": "Haverford North",
      "onMarketRate": 7.085598318475033,
      "count": 287
    },
    {
      "neighborhood": "Wynnefield Heights",
      "onMarketRate": 2.4275424619323283,
      "count": 1003
    },
    {
      "neighborhood": "Cobbs Creek",
      "onMarketRate": 4.240419414848637,
      "count": 3038
    },
    {
      "neighborhood": "Walnut Hill",
      "onMarketRate": 5.937951821991329,
      "count": 2037
    },
    {
      "neighborhood": "Cedar Park",
      "onMarketRate": 4.133640071107396,
      "count": 2841
    },
    {
      "neighborhood": "Woodland Terrace",
      "onMarketRate": 6.3652026549922365,
      "count": 1123
    },
    {
      "neighborhood": "Callowhill",
      "onMarketRate": 5.370545320946837,
      "count": 2177
    },
    {
      "neighborhood": "West Poplar",
      "onMarketRate": 7.352449833926212,
      "count": 2557
    },
    {
      "neighborhood": "Strawberry Mansion",
      "onMarketRate": 5.693323051800782,
      "count": 1647
    },
    {
      "neighborhood": "Packer Park",
      "onMarketRate": 9.387333730395083,
      "count": 1138
    },
    {
      "neighborhood": "Wynnefield",
      "onMarketRate": 4.699231813284453,
      "count": 1582
    },
    {
      "neighborhood": "West Passyunk",
      "onMarketRate": 4.086402050218143,
      "count": 1526
    },
    {
      "neighborhood": "East Passyunk",
      "onMarketRate": 2.598016060462917,
      "count": 1971
    },
    {
      "neighborhood": "Newbold",
      "onMarketRate": 3.39125371579173,
      "count": 1501
    },
    {
      "neighborhood": "Stadium District",
      "onMarketRate": 3.0231054147804097,
      "count": 336
    },
    {
      "neighborhood": "East Kensington",
      "onMarketRate": 5.7787835744837945,
      "count": 2868
    },
    {
      "neighborhood": "Elmwood",
      "onMarketRate": 2.6847662141779796,
      "count": 990
    },
    {
      "neighborhood": "Girard Estates",
      "onMarketRate": 3.9363580602591015,
      "count": 1141
    },
    {
      "neighborhood": "Eastwick",
      "onMarketRate": 9.116674539442608,
      "count": 168
    }
  ]

export default onMarketData;