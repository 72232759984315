import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { buildColorGrade } from '../../../util/colors'
import { unwrapNestedArray, calculateDaysToLease} from "../../../util";
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode'
import { mapBoxDrawStyles } from './constants';

export const initializeMap = ({mapContainer, lng, lat, zoom}) => {
    return new mapboxgl.Map({
        container: mapContainer.current,
        style: process.env.REACT_APP_MAP_STYLE,
        center: [lng, lat],
        zoom: zoom,
        maxZoom: 17.5,
        minZoom: 14
    })
}

export const deleteLayer = (m, l) => {
  if(m.getLayer(l)) {
    m.removeLayer(l)
  }
}

export const deleteSource = (m, l) => {
  if(m.getSource(l)) {
    m.removeSource(l)
  }
}

export const toGeoJSON = (docs) => {
  return {
    type: 'FeatureCollection',
    features: docs.map(doc => {
      return {
        type: 'Feature',
        properties: doc,
        geometry: {
          type: 'Point',
          coordinates: [doc.lng, doc.lat]
        }
      }
    })
  }
}

export const initializeMapControls = (map) => {
    map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-right');
    map.addControl(new mapboxgl.GeolocateControl(), 'top-right');
    map.addControl(new mapboxgl.FullscreenControl(), 'top-right');
}

export const initializeDrawControls = ({map, Draw}) => {
    var modes = MapboxDraw.modes;
    modes.static = StaticMode;
    Draw.current = new MapboxDraw({
      modes: modes,
      userProperties: true,
      displayControlsDefault: false,
      styles: mapBoxDrawStyles
    });
    map.addControl(Draw.current, 'top-left');
}

export const attachMapListeners = ({map, Draw, dispatch, viewerDispatch, stateRef}) => {
  dispatch({ type: "SET_MAP", value: map });

  map.on("load", function () {
    map.resize();
  });

  map.on("move", () => {
    if(stateRef.current.radius || stateRef.current.zipcode || stateRef.current.neighborhood) return;
    dispatch({ type: "SET_LNG", value: map.getCenter().lng.toFixed(4) });
    dispatch({ type: "SET_LAT", value: map.getCenter().lat.toFixed(4) });
    dispatch({ type: "SET_ZOOM", value: map.getZoom().toFixed(2) });
  });

  map.on("mouseenter", "points-clustered", () => {
    map.getCanvas().style.cursor = "pointer";
  });
  map.on("mouseleave", "points-clustered", () => {
    map.getCanvas().style.cursor = "";
  });
  map.on("mouseenter", "points-unclustered", () => {
    map.getCanvas().style.cursor = "pointer";
  });
  map.on("mouseleave", "points-unclustered", () => {
    map.getCanvas().style.cursor = "";
  });
  map.on("draw.create", (e) => {
    // using Draw.getAll and Draw.delete to ensure only one polygon is drawn at a time
    let data = Draw.current.getAll();
    // delete all polygons which are not the newly drawn one
    Object.keys(data.features).forEach((key) => {
      if (data.features[key].id !== e.features[0].id) {
        Draw.current.delete(data.features[key].id);
      }
    });
    dispatch({ type: "SET_CUSTOM_POLYGON", value: e.features[0] });
  });
  map.on("draw.update", (e) => {
    dispatch({ type: "SET_CUSTOM_POLYGON", value: e.features[0] });
  });
  map.on("draw.delete", () => {
    dispatch({ type: "POLYGON_MODE_ON", value: false });
    dispatch({ type: "SET_CUSTOM_POLYGON", value: undefined });
  });
  map.on("draw.modechange", (e) => {
    if (e.mode === "draw_polygon") {
      dispatch({ type: "POLYGON_MODE_ON", value: true });
    } else {
      Draw.current.changeMode("static");
      dispatch({ type: "POLYGON_MODE_ON", value: false });
    }
  });
  map.on('click', 'points-unclustered', (e) => {
    viewerDispatch({ type: 'SET_PROPERTY_ID', value: e.features[0].properties.id })
    viewerDispatch({ type: 'SET_PROPERTY_OPEN', value: true})
  })

  map.on('click', 'points-clustered', (e) => {
    let buildingID = e.features[0].properties.building_id
    viewerDispatch({ type: 'SET_BUILDING_ID', value: buildingID })
    viewerDispatch({ type: 'SET_BUILDING_OPEN', value: true })
  })
};

export const clearMap = ({map, markers}) => {
    deleteLayer(map, 'points-unclustered')
    deleteLayer(map, 'points-clustered')
    deleteLayer(map, 'points-cluster-count')
    deleteLayer(map, 'neighborhoods-line')
    deleteLayer(map, 'zipcodes-line')
    deleteLayer(map, 'polygon')
    deleteSource(map, 'point-data')
    deleteSource(map, 'neighborhood-data')
    deleteSource(map, 'zipcode-data')
    deleteSource(map, 'circle-radius')
    // delete all markers
    if (markers) markers.forEach(marker => marker.remove());
}

export const injectMap = ({map, neighborhood, zipcode, radius, lng, lat, data, highlight_attribute, dispatch}) => {
    if(neighborhood) {
        map.addSource('neighborhood-data', {
          type: 'geojson',
          data: neighborhood,
        });

        // Add the line layer
        map.addLayer({
          id: 'neighborhoods-line',
          source: 'neighborhood-data',
          'type': 'line',
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          'paint': {
            'line-color': '#fbb03b',
            'line-dasharray': [0.2, 2],
            'line-width': 5
          }
        });
      }
      if(zipcode) {
        map.addSource('zipcode-data', {
          type: 'geojson',
          data: zipcode,
        });

        // Add the line layer
        map.addLayer({
          id: 'zipcodes-line',
          source: 'zipcode-data',
          'type': 'line',
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          'paint': {
            'line-color': '#fbb03b',
            'line-dasharray': [0.2, 2],
            'line-width': 5
          }
        });
      }
      if(radius) {
        map.addSource('circle-radius', createGeoJSONCircle({center: [lng, lat], radiusInMiles: radius}))

        map.addLayer({
          'id': 'polygon',
          'type': 'line',
          'source': 'circle-radius',
          'layout': {},
          'paint': {
            'line-color': '#fbb03b',
            'line-dasharray': [2, 2],
            'line-width': 5
          }
        });
      }
      if (radius || neighborhood || zipcode) {
        const marker = new mapboxgl.Marker({color: 'purple'})
            .setLngLat([lng, lat])
            .addTo(map);
        dispatch({type: 'ADD_MARKER', value: marker})
      }

      map.addSource('point-data', {
        type: 'geojson',
        data: toGeoJSON(data.properties)
      })

      map.addLayer({
        id: 'points-unclustered',
        type: 'circle',
        source: 'point-data',
        filter: ['!', ['has', 'count']],
        paint: {
          'circle-radius': [
            'interpolate', ['linear'], ['zoom'],
            14, 3.5,
            15, 4.5,
            16.5, 5.5,
            17.5, 8
          ],
          'circle-stroke-width': 0.5,
          'circle-color': [
            'case',
            ['has', highlight_attribute],
            [
              'step',
              ['get', highlight_attribute],
              ...buildColorGrade(
                highlight_attribute,
                data.stats?.[highlight_attribute]?.quantiles
              )
            ],
            'rgba(255,0,0,1)'
          ],
          'circle-stroke-color': 'white'
        }
      })

      map.addLayer({
        id: 'points-clustered',
        type: 'circle',
        source: 'point-data',
        filter: ['has', 'count'],
        paint: {
          'circle-radius': [
            'interpolate', ['linear'], ['zoom'],
            14, 3.5,
            15, 4.5,
            16.5, 5.5,
            17.5, 8
          ],
          'circle-stroke-width': 0.5,
          'circle-color': [
            'step',
            ['get', 'avg_score'],
            ...buildColorGrade(
              highlight_attribute,
              data.stats?.[highlight_attribute]?.quantiles
            )
          ],
          'circle-stroke-color': 'white'
        }
      })

      map.addLayer({
        id: 'points-cluster-count',
        type: 'symbol',
        source: 'point-data',
        filter: ['has', 'count'],
        minzoom: 16.5,
        layout: {
          'text-field': ['get', 'count'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': [
            'interpolate', ['linear'], ['zoom'],
            15, 8,
            17.5, 12
          ]
        }
      })
}

export const queryStringToState = (search) => {
  const query = search.substring(1);
  const parts = query.split('&');
  let result = {};

  parts.forEach(part => {
    const [key, value] = part.split('=');
    if (!key) return;
    if (key === 'q') {
      result.searchArea = decodeURIComponent(value);
    } else if (key === 'propertyID') {
      result.propertyID = value;
    } else if (key === 'buildingID') {
      result.buildingID = value;
    } else {
      if (!result.filter) {
        result.filter = {};
      }
      result.filter[key] = value;
    }
  });

  return result;
}

export const stateToQueryString = ({filters, search, propertyID, buildingID}) => {
  let query = [];

  for (let key in filters) {
    if (filters[key]) {
      query.push(`${key}=${filters[key]}`);
    }
  }

  if (search) {
    query.push(`q=${search}`);
  }

  if (propertyID) {
    query.push(`propertyID=${propertyID}`)
  } else if (buildingID) {
    query.push(`buildingID=${buildingID}`)
  }

  return query.join('&');
}

export function getBounds(lng, lat, map, custom_polygon, radius, neighborhood, zipcode) {
  let polygon
  if (custom_polygon) {
    polygon = unwrapNestedArray(custom_polygon.geometry.coordinates)
  } else if(neighborhood) {
    polygon = unwrapNestedArray(neighborhood.geometry.coordinates)
  } else if(zipcode) {
    polygon = unwrapNestedArray(zipcode.geometry.coordinates)
  } else if(radius) {
    polygon = createGeoJSONCircle({center: [lng, lat], radiusInMiles: radius}).data.features[0].geometry.coordinates[0]
  } else {
    let bounds = map.getBounds()
    polygon = [
      bounds.getSouthWest().toArray(),
      bounds.getSouthEast().toArray(),
      bounds.getNorthEast().toArray(),
      bounds.getNorthWest().toArray(),
      bounds.getSouthWest().toArray(),
    ]
  }
  return polygon
}

export const processDaysToLease = (properties) => {
  properties.forEach(p => {
    if (p.leases) {
      p.days_to_lease = calculateDaysToLease(p.leases);
    }
  });
}

export const fitMapToCircleBounds = ({map, center, radiusInMiles}) => {
  const circleData = createGeoJSONCircle({center, radiusInMiles});
  const circleCoords = circleData.data.features[0].geometry.coordinates[0];

  // Get the min and max latitudes and longitudes from the circle's coordinates
  let lats = circleCoords.map(coord => coord[1]);
  let lngs = circleCoords.map(coord => coord[0]);
  const southWest = [Math.min(...lngs), Math.min(...lats)];
  const northEast = [Math.max(...lngs), Math.max(...lats)];

  // Fit the map to the circle's bounds
  map.fitBounds([southWest, northEast], {
      padding: 20,
  });
};

export const createGeoJSONCircle = function({center, radiusInMiles, points}) {
  if(!points) points = 64;

  var coords = {
      latitude: parseFloat(center[1]),
      longitude: parseFloat(center[0])
  };

  var miles = radiusInMiles;
  var km = miles * 1.60934;

  var ret = [];
  var distanceX = km/(111.320*Math.cos(coords.latitude*Math.PI/180));
  var distanceY = km/110.574;

  var theta, x, y;
  for(var i=0; i<points; i++) {
      theta = (i/points)*(2*Math.PI);
      x = distanceX*Math.cos(theta);
      y = distanceY*Math.sin(theta);

      ret.push([coords.longitude+x, coords.latitude+y]);
  }
  ret.push(ret[0]);

  return {
      "type": "geojson",
      "data": {
          "type": "FeatureCollection",
          "features": [{
              "type": "Feature",
              "geometry": {
                  "type": "Polygon",
                  "coordinates": [ret]
              }
          }]
      }
  };
};

export const groupByBuildingID = ({properties, key}) => {
  let building_key = {};
  let new_props = [];

  for (let prop of properties) {
      let b_id = prop.building_id;
      if (b_id === undefined || b_id === null) continue; // Skip if there's no building ID

      if (!building_key[b_id]) building_key[b_id] = [];
      building_key[b_id].push(prop);
  }

  for (let b in building_key) {
      // Single Property
      if (building_key[b].length === 1) {
          new_props.push(building_key[b][0]);
      }
      // Multiple Properties
      else {
          new_props.push({
              building_id: b,
              lat: building_key[b][0].lat,
              lng: building_key[b][0].lng,
              count: building_key[b].length,
              avg_score: building_key[b].reduce((p, c) => {
                  return p + parseFloat(c[key]);
              }, 0) / building_key[b].length
          });
      }
  }
  return new_props;
}