import {useContext} from "react";
import {MapContext} from "../../contexts/MapContext";
import {priceFormatter} from "../../util";
import {getAttributeColors} from "../../util/colors";

const MapLegend = () => {
  const state = useContext(MapContext)
  const { highlight_attribute, data } = state

  if(!data) return <></>

  let legend_values = data?.stats?.[highlight_attribute]?.quantiles
  if(legend_values) {
    legend_values = legend_values.map(priceFormatter.format)
  }
  else {
    legend_values = ['F','D','C','B','A']
  }

  let colors = getAttributeColors(highlight_attribute)

  return (
    <div style={{
      display: 'flex',
      position: 'absolute',
      right: '0',
      bottom: '0'
    }}>
      {colors.map((color, idx) => {
        return (
          <div key={idx}>
            <div style={{
              height: 40,
              width: 75,
              background: color,
              display: 'inline-block',
              verticalAlign: 'middle'
            }}>
              <div style={{
                background: 'inherit',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                color: 'transparent',
                filter: 'invert(1) grayscale(1) contrast(100)',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 900
              }}>{legend_values[idx]}</div>
            </div>
            <div style={{
              display: 'inline-block'
            }}></div>
          </div>
        )
      })}
    </div>
  )
}

export default MapLegend