import { Menu, Dropdown, Button, Grid, Container } from "semantic-ui-react";
import SearchBar from "../../common/SearchBar/SearchBar";
import React, { useContext } from "react";
import { MapContext, MapDispatchContext } from "../../../contexts/MapContext";
import { handleSearch } from "../../../util/handleSearch";

import './MapMenu.scss'
import MapFilter from "../MapFilter/MapFilter";

const MapMenu = () => {
  const state = useContext(MapContext);
  const { map } = state;
  const dispatch = useContext(MapDispatchContext);

  const mapSearchCallback = (e, data) => {
    handleSearch({
      dispatch,
      data: data.result,
      additionalData: { radius: 0.2, map: map }
    })
  }

  return (
    <Menu inverted top className={'mapMenu'} size={'large'}>
      <Menu.Item className={'searchItem'}>
        <SearchBar resultCallback={mapSearchCallback} />
      </Menu.Item>
      <Menu.Item className="buttons-grid">
        <MapFilter />
      </Menu.Item>
    </Menu>
  );
};

/*<Grid container>
            <Grid.Row columns={3}>
              <Grid.Column verticalAlign={'middle'}>
                <BedsButton state={state} dispatch={dispatch} />
              </Grid.Column>
              <Grid.Column verticalAlign={'middle'}>
                <BathsButton state={state} dispatch={dispatch} />
              </Grid.Column>
              <Grid.Column verticalAlign={'middle'}>
                <Dropdown item style={{ width: "100%", border: "1px solid black" }} text={!state.radius ? "Select Area" : ""}>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <NeighborhoodSelector />
                    </Dropdown.Item>
                    <Dropdown.Item
                      content={"Select Polygon"}
                      onClick={() => {
                        dispatch({
                          type: "POLYGON_MODE_ON",
                          value: true,
                        });
                      }}
                    />
                    {state.custom_polygon && (
                      <Dropdown.Item>
                        <Button
                          fluid
                          icon="close"
                          content="Clear Polygon"
                          onClick={() => {
                            dispatch({
                              type: "POLYGON_MODE_ON",
                              value: false,
                            });
                            dispatch({
                              type: "SET_CUSTOM_POLYGON",
                              value: undefined,
                            });
                          }}
                        />
                      </Dropdown.Item>
                    )}
                    {state.neighborhood && (
                      <Dropdown.Item>
                        <Button
                          fluid
                          icon="close"
                          content="Clear Neighborhood"
                          onClick={() => {
                            dispatch({
                              type: "SET_NEIGHBORHOOD",
                              value: undefined,
                            });
                          }}
                        />
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
            </Grid.Row>
          </Grid>*/

export default MapMenu;
