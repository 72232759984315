import {Table, Card} from 'semantic-ui-react'
import {useContext, useState, useEffect} from 'react'
import {MapContext, MapDispatchContext} from "../../../contexts/MapContext";
import './RentTableModule.scss'
import getGradeSummary from "../../../util/getGradeSummary";

const RentTableModule = () => {
  const state = useContext(MapContext)
  const { data, } = state

  const [ gradeSummary, setGradeSummary ] = useState()

  useEffect(() => {
    if(!data?.properties) return
    let summary = getGradeSummary(data.properties)
    setGradeSummary(summary)
  }, [ data ])

  return !data ? <>Loading</> : (
    <Card fluid>
      <Card.Content>
        <Card.Header>Market Rents</Card.Header>
        <Card.Meta>By property grade and unit type</Card.Meta>
        <Table definition celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>Studio</Table.HeaderCell>
              <Table.HeaderCell>1 Bed</Table.HeaderCell>
              <Table.HeaderCell>2 Bed</Table.HeaderCell>
              <Table.HeaderCell>3 Bed</Table.HeaderCell>
              <Table.HeaderCell>4 Bed</Table.HeaderCell>
              <Table.HeaderCell>5+ Bed</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              ['A', 'B', 'C', 'D', 'F'].map(grade => (
                <Table.Row key={grade}>
                  <Table.Cell>
                    {`Grade ${grade}`}
                  </Table.Cell>
                  <RentCell grade_summary={gradeSummary} grade={grade} beds={'0'} />
                  <RentCell grade_summary={gradeSummary} grade={grade} beds={'1'} />
                  <RentCell grade_summary={gradeSummary} grade={grade} beds={'2'} />
                  <RentCell grade_summary={gradeSummary} grade={grade} beds={'3'} />
                  <RentCell grade_summary={gradeSummary} grade={grade} beds={'4'} />
                  <RentCell grade_summary={gradeSummary} grade={grade} beds={'5'} />
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
      </Card.Content>
    </Card>
  )
}

const getClassName = (grade) => `rent-table ${grade}-bg`;

const handleSelect = ({grade, beds, dispatch}) => {
  dispatch({ type: 'SET_FILTER', value: { grade, beds } })
}

const RentCell = ({ grade_summary, grade, beds }) => {
  const dispatch = useContext(MapDispatchContext)
  let d = grade_summary?.[grade]?.[parseInt(beds)];

  if(!d) {
    return <Table.Cell className={`rent-table ${getClassName(grade)} empty`} />
  }
  
  return (
    <Table.Cell
      className={getClassName(grade)}
      selectable textAlign={'center'}
      onClick={() => handleSelect({grade, beds, dispatch})}
    >
      {d.average ? '$' + Math.floor(d.average) + ' ' : ''}
      <span style={{
        color: 'rgba(0,0,0,0.5)',
        fontSize: 11,
        fontStyle: 'italic'
      }}>({d.count})
      </span>
    </Table.Cell>
  );
};



export default RentTableModule