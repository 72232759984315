import React, {createContext, useReducer} from 'react'

export const SearchContext = createContext(undefined)
export const SearchDispatchContext = createContext(undefined)

const search_initial_state = {
  loading: false,
  results: [],
  isSelected: false,
  selectedData: null,
  value: ''
}

const reducer = (state, action) => {
  switch(action.type) {
    case 'QUERY':
      return { ...state, value: action.query, loading: true, isSelected: false }
    case 'RESET':
      return search_initial_state
    case 'RESULTS':
      return { ...state, results: action.results, loading: false }
    case 'SELECT':
      return { ...state, value: action.value, isSelected: true }
    case 'SELECT_DATA':
      return { ...state, selectedData: action.data }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const SearchContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, search_initial_state)

  return (
    <SearchContext.Provider value={state}>
      <SearchDispatchContext.Provider value={dispatch}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchContext.Provider>
  )
}

export default SearchContextProvider
