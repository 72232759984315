import {Card, Message} from "semantic-ui-react";
import {useContext, useEffect, useState} from "react";
import {MapContext} from "../../../contexts/MapContext";
import {getGradeExtended, formatNumber} from "../../../util";
import InsightsIcon from "../../../assets/icons/insights.svg";
import './QualityScaleModule.scss'

const QualityScaleModule = () => {
  const { data } = useContext(MapContext)
  const [ quality, setQuality ] = useState()

  useEffect(() => {
    if(!data || !data.properties) return

    let q = data.properties.reduce((sum, p) => {
      return sum + p.property_score
    }, 0) / data.properties.length

    let grade = getGradeExtended(q)
    // for now let color be the same as the grade but without +/- and lowercase
    let color = grade.replace(/[+-]/g, '').toLowerCase()
    setQuality({
      grade,
      color,
      score: q
    })

  }, [data])

  // const tooltipPosition = quality ? `${quality?.score * 100}%` : '0%';

  return (
    <Card fluid className="quality-scale-card">
      <Card.Content>
      <div className={`grade-icon grade-${quality?.color}`}>
        {quality?.grade}
      </div>
      <Card.Header>Average Quality</Card.Header>
        <Card.Meta>
          Avg property quality of selected area
        </Card.Meta>
        <Message className='insights-box'>
          <div className="icon-container">
            <img src={InsightsIcon} alt="AI Analyst" />
          </div>
          <div className='textbox-container'>
            <p>{`xxxx`}</p>
            {/* <p>{`${parseInt(score * 100)}%`}</p> */}
          </div>
        </Message>
        {/* <div className={'bar'} style={{ '--dash-position': tooltipPosition }}>
        { quality && quality.score && quality.grade && (
          <div className='tooltip' style={{ left: tooltipPosition }}>
            <p className='tooltip-secondary-text'>{formatNumber(quality.score * 100)}%</p>
          </div>
        )}
        </div> */}
      </Card.Content>
    </Card>
  )
}

export default QualityScaleModule