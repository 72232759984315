import {Card,Icon} from "semantic-ui-react";

import {useContext, useEffect, useState} from "react";
import {MapContext} from "../../../contexts/MapContext";
import { formatNumber, computePercentile, computeZScore, determineRatingByPercentile } from "../../../util";
import './RentChangeModule.scss'

const calcAvgRent = (properties) => {
  let totalRentForAllProperties = 0;

  // Iterate over each property
  for (const property of properties) {
    let totalRentForThisProperty = 0;
    const leases = property.leases;

    // Calculate total rent for this property
    for (const lease of leases) {
      if (lease.lease_price) {
          totalRentForThisProperty += lease.lease_price;
      }
    }

    // Calculate average rent for this property
    const avgRentForThisProperty = totalRentForThisProperty / leases.length;

    // Add the average rent of this property to the total for all properties
    totalRentForAllProperties += avgRentForThisProperty;
  }
  
  // Return the average rent for all properties
  return totalRentForAllProperties / properties.length;
};

const RentChangeModule = () => {
  const { data } = useContext(MapContext)
  const [ avgRent, setAvgRent ] = useState()
  const [ rating, setRating ] = useState()

  useEffect(() => {
    if(!data || !data.properties) return
    let avgRent = calcAvgRent(data.properties)
    console.log('average_rent', avgRent)
    setAvgRent(avgRent)
  }, [data]);
  

  return (
    <Card fluid className='on-market-rate-card'>
      <Card.Content>
        <Card.Header>Rent</Card.Header>
        { avgRent &&
          <>
            <div className="content-container">
                <div className="time-metric">
                    ${Math.round(avgRent)}
                </div>
                {/* <div className={`rating ${rating.color}`}>
                    {rating.text}
                </div> */}
            </div>
            <a href="#" className="link">
              View Comps
              <Icon name='arrow right' />
            </a>
          </>
        }

      </Card.Content>
    </Card>
  )
}

export default RentChangeModule