//import FeatureService from 'mapbox-gl-arcgis-featureserver'
import React, {useRef, useEffect, useContext} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import debounce from 'lodash.debounce'
import axios from "axios"
import {ungroupProperties} from "../../../util";
import {MapContext, MapDispatchContext} from "../../../contexts/MapContext";
import { SearchContext, SearchDispatchContext } from '../../../contexts/SearchContext';
import {ViewerContext, ViewerDispatchContext} from "../../../contexts/ViewerContext";
import MapLegend from "../MapLegend";
import { handleNeighborhoodMap } from '../NeighborhoodSelector';
import { handleZipcodeMap } from '../ZipCodeSelector';
import { attachMapListeners, initializeDrawControls, initializeMap, initializeMapControls, clearMap, injectMap, queryStringToState, stateToQueryString, processDaysToLease, getBounds, fitMapToCircleBounds, groupByBuildingID } from './functions'
import './Map.scss'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

const Map = () => {
  const mapContainer = useRef(null)
  const mapSet = useRef(null)
  const Draw = useRef(null);
  const requestFunction = useRef(null)
  const renderFunction = useRef(null)
  const stateRef = useRef();

  const state = useContext(MapContext)
  const searchState = useContext(SearchContext)
  const searchDispatch = useContext(SearchDispatchContext)
  const dispatch = useContext(MapDispatchContext)
  const viewerContext = useContext(ViewerContext)
  const viewerDispatch = useContext(ViewerDispatchContext)
  
  const {
    lng, lat, zoom, filter, map,
    highlight_attribute, neighborhood, zipcode,
    custom_polygon, polygonModeOn, radius,
  } = state

  const { propertyID, propertyData, buildingID, buildingData } = viewerContext

  const { value: searchValue, isSelected: isSearchValueSelected } = searchState

  const navigate = useNavigate();
  const location = useLocation();
  const initialStateFromUrl = queryStringToState(location.search);

  if(!renderFunction.current) {
    renderFunction.current = ({ map, data: raw_data, highlight_attribute, neighborhood, zipcode, radius, lng, lat, markers }) => {
      if(!raw_data) return

      let data = {
        properties: groupByBuildingID({
          properties: ungroupProperties(raw_data.properties),
          key: highlight_attribute
        })
      }
      dispatch({type: 'SET_MAP_DATA', value: data})

      clearMap({map, markers})

      injectMap({map ,neighborhood, zipcode, radius, lng, lat, data, highlight_attribute, dispatch})
    }
  }

  if(!requestFunction.current) {
    requestFunction.current = debounce(async ({ map, filter, highlight_attribute, neighborhood, zipcode, custom_polygon, radius, lat, lng, markers }) => {
      if (!map) return;
      let polygon = await getBounds(lng, lat, map, custom_polygon, radius, neighborhood, zipcode);

      let raw_data = await axios.post(process.env.REACT_APP_API_URL + 'mapQuery', {
        bounds: polygon,
        beds: filter?.beds,
        baths: filter?.baths,
        grade: filter?.grade,
        propertyType: filter?.propertyType,
      });

      // Post processing
      processDaysToLease(raw_data.data.properties);

      dispatch({type: 'SET_DATA', value: raw_data.data})
      renderFunction.current({ map, data: raw_data.data, highlight_attribute, neighborhood, zipcode, radius, lat, lng, markers });
    }, 300)
  }

  useEffect(function() {
    if(mapSet.current) return
    mapSet.current = true

    let local_map = initializeMap({mapContainer, lng, lat, zoom})

    initializeDrawControls({map: local_map, Draw})

    initializeMapControls(local_map)
    attachMapListeners({map: local_map, Draw, dispatch, viewerDispatch, stateRef})
  })

  useEffect(() => {
    stateRef.current = state;
  });

  useEffect(() => {
    if(isSearchValueSelected || !initialStateFromUrl) return
    if (initialStateFromUrl?.filter) dispatch({type: 'SET_FILTER', value: initialStateFromUrl.filter});
    if (initialStateFromUrl?.searchArea) searchDispatch({type: 'SELECT', value: initialStateFromUrl.searchArea});
    if (initialStateFromUrl?.propertyID) viewerDispatch({type: 'SET_PROPERTY_ID', value: initialStateFromUrl.propertyID});
    else if (initialStateFromUrl?.buildingID) viewerDispatch({type: 'SET_BUILDING_ID', value: initialStateFromUrl.buildingID});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(!map || !neighborhood) return
    handleNeighborhoodMap({
      mapname: neighborhood?.properties?.mapname,
      ogMap: map,
      dispatch: dispatch,
    })
  }, [dispatch, map, neighborhood])

  useEffect(() => {
    if(!map || !zipcode) return
    handleZipcodeMap({
      zipcodeInput: zipcode?.properties?.CODE,
      ogMap: map,
      dispatch: dispatch,
    })
  }, [dispatch, map, zipcode])

  useEffect(() => {
    // Condition to check if either filter is set or isSearchValueSelected is true.
    console.log('propertyID', propertyID)
    console.log('propertyData', propertyData)
    console.log('buildingID', buildingID)
    console.log('buildingData', buildingData)
    if (filter || isSearchValueSelected || propertyID || buildingID) {
        let search = isSearchValueSelected ? searchValue : null;
        let query = stateToQueryString({filters: state.filter, search, propertyID, buildingID});
        navigate(`?${query}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, searchValue, isSearchValueSelected, propertyID, buildingID, propertyData, buildingData]);


  // Debounce Request Function
  useEffect(() => {
      if((!neighborhood && !zipcode && !custom_polygon && !radius && !isSearchValueSelected)) {
          if(requestFunction.current) {
              requestFunction.current(state);
          }
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lng, lat, zoom]);

  useEffect(() => {
    if(requestFunction.current){
      requestFunction.current(state)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [neighborhood, zipcode, custom_polygon, radius, filter, map, highlight_attribute]);

  useEffect(() => {
    if (isSearchValueSelected && requestFunction.current) {
        requestFunction.current(state);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if(!map || !radius) return
      fitMapToCircleBounds({
        map,
        center: [lng, lat],
        radiusInMiles: radius,
      });
  }, [map, radius])

  useEffect(() => {
    if(!map || !Draw) return
    if(polygonModeOn) {
      Draw.current.changeMode('draw_polygon')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygonModeOn])

  useEffect(() => {
    if(Draw.current && !custom_polygon && Draw.current.getAll().features.length > 0) {
      Draw.current.deleteAll()
    }
  }, [custom_polygon]);

  return (
    <div className='sticky-map'>
      <div ref={mapContainer} className='map-container'>
        <MapLegend />
      </div>
    </div>

  )
}

export default Map
