const getGradeFromScore = (input) => {
  if (input >= 0.8) {
    return 'A'
  } else if (input >= 0.6) {
    return 'B'
  } else if (input >= 0.4) {
    return 'C'
  } else if (input >= 0.2) {
    return 'D'
  } else {
    return 'F'
  }
}

export default getGradeFromScore