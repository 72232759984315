import _ from "lodash"
import getGradeFromScore from '../../../util/getGradeFromScore'
import {degreesToRadians, calculateDaysToLease} from "../../../util";

export const sortAndSliceData = ({
  fullData,
  column,
  direction,
  page,
  pageSize
}) => {
  let sortedData;
  if (column === "grade") {
    sortedData = _.sortBy(fullData, [
      (property) => {
        const grade = getGradeFromScore(property.property_score);
        return grade;
      },
    ]);
  } else if (column === "last_lease_date") {
    sortedData = _.sortBy(fullData, [
      (property) => {
        const lastLeaseDate =
          property.leases[property.leases.length - 1]?.lease_date;
        return new Date(lastLeaseDate);
      },
    ]);
  } else {
    sortedData = _.sortBy(fullData, [column]);
  }

  if (direction !== "ascending") {
    sortedData = sortedData.reverse();
  }

  return sortedData.slice((page - 1) * pageSize, page * pageSize);
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_SORT":
      const newDirection =
        state.direction === "ascending" ? "descending" : "ascending";
      return {
        ...state,
        column: action.column,
        direction: newDirection,
      };
    case "RESET_PAGE":
      return {
        ...state,
        page: 1,
      };
    case "SET_PAGE":
      return {
        ...state,
        page: action.page,
      };
    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageSize: action.pageSize,
      };
    default:
      throw new Error();
  }
};

export const preprocessProperties = ({ properties, searchContext }) => {
  return  properties.map(property => {
    let days_to_lease = calculateDaysToLease(property.leases)
    let updatedProperty = {
      ...property,
      days_to_lease
    };

    if (searchContext.selectedData) {
      const lat1 = searchContext.selectedData.center[1]
      const lng1 = searchContext.selectedData.center[0]
      const distance = calculateDistance(lat1, lng1, property.lat, property.lng)
      updatedProperty.distance = parseFloat(distance).toFixed(2)
    }

    return updatedProperty
  })
}

function calculateDistance({lat1, lng1, lat2, lng2}) {
  // Earth's radius in miles
  const R = 3958.8;

  // Convert degrees to radians
  lat1 = degreesToRadians(lat1);
  lng1 = degreesToRadians(lng1);
  lat2 = degreesToRadians(lat2);
  lng2 = degreesToRadians(lng2);

  const dLat = lat2 - lat1;
  const dLon = lng2 - lng1;

  // Haversine formula
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1) * Math.cos(lat2) * 
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
            
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distance in miles
  const distance = R * c;

  return distance;
}
