import axios from 'axios'
import { extractZipcode } from '../../util'

let zipcodeData
axios.get(process.env.REACT_APP_API_URL + 'getZipcodes')
  .then(resp => {
    zipcodeData = resp.data
  })

// Check if the zipcode is valid
export const validateZipcode = (data) => {
  let zipcodeInput = extractZipcode(data);

  // check if its available in the zipcode data
  let zipcode = zipcodeData.features
    .find(f => f.properties.CODE === zipcodeInput);
  if(!zipcode) return false

  return true
}

export const handleZipcodeSelect = ({ dispatch, zipcodeInput }) => {
  const r = getZipcode({zipcodeInput, dispatch})
  if(!r?.zipcode) return false
  dispatch({ type: 'SET_ZIPCODE', value: r.zipcode })
  return true
};

export const getZipcode = ({zipcodeInput, dispatch}) => {
  const zipcode = zipcodeData.features
    .find(f => f.properties.CODE == zipcodeInput);
  const polygons = zipcode?.geometry.coordinates
  if(!zipcode || !polygons) return


  let totalLat = 0;
  let totalLng = 0;
  let totalPoints = 0;

  for (const ring of polygons[0]) {
    totalLat += ring[1];
    totalLng += ring[0];
    totalPoints++;
  }

  const centerLat = totalLat / totalPoints;
  const centerLng = totalLng / totalPoints;
  dispatch({ type: 'SET_LAT', value: centerLat })
  dispatch({ type: 'SET_LNG', value: centerLng })
  return {
    zipcode,
    polygons,
    zipcodeCenter: [centerLng, centerLat],
  };
}

export const handleZipcodeMap = ({ zipcodeInput, ogMap, dispatch }) => {
  const {zipcodeCenter, polygons} = getZipcode({zipcodeInput, dispatch})
  if (zipcodeCenter) {
    ogMap.flyTo({
      center: zipcodeCenter,
      essential: true
    });

    let lats = polygons.flat(1).map(coord => coord[1]);
    let lngs = polygons.flat(1).map(coord => coord[0]);
    const southWest = [Math.min(...lngs), Math.min(...lats)];
    const northEast = [Math.max(...lngs), Math.max(...lats)];
    ogMap.fitBounds([southWest, northEast], {
      padding: 20,
    });
    return true
  }
}
