import {Grid, Card} from "semantic-ui-react";
import { Container } from "semantic-ui-react";
import MapMenu from "../../components/dashboard/MapMenu/MapMenu";
import TabMenu from "../../components/dashboard/TabMenu/TabMenu";
import Map from "../../components/dashboard/Map/Map";

import PropertyViewer from "../../components/dashboard/PropertyViewer/PropertyViewer";
import BuildingViewer from "../../components/dashboard/BuildingViewer/BuildingViewer";
import {Navigation} from "../../components/common/Navigation/Navigation";
import React, {useState} from "react";

import './Dashboard.scss'
import {LeasePriceHistogramModule} from "../../components/dashboard/LeasePriceHistogramModule/LeasePriceHistogramModule";
import {GradePieChartModule} from "../../components/dashboard/GradePieChartModule/GradePieChartModule";
import {SeasonalityModule} from "../../components/dashboard/SeasonalityModule/SeasonalityModule";
import {PropertiesTable} from "../../components/dashboard/PropertiesTable/PropertiesTable";
import RentTableModule from "../../components/dashboard/RentTableModule/RentTableModule";
import GradeSensitivityModule from "../../components/dashboard/GradeSensitvityModule/GradeSensitivityModule";
import RentGraphModule from "../../components/dashboard/RentGraphModule/RentGraphModule";
import QualityScaleModule from "../../components/dashboard/QualityScaleModule/QualityScaleModule";
import TimeOnMarketModule from "../../components/dashboard/TimeOnMarketModule/TimeOnMarketModule";
import LeaseByMonthTableModule from "../../components/dashboard/LeaseByMonthTableModule/LeaseByMonthTableModule";
import OnMarketRateModule from "../../components/dashboard/OnMarketRateModule/OnMarketRateModule";
import RentChangeModule from "../../components/dashboard/RentChangeModule/RentChangeModule";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');

  const OverviewGrid = (
    <>
      <Grid columns={3} stackable className="equal-height-grid">
        {/* Row 1 */}
        <Grid.Column>
          <OnMarketRateModule />
        </Grid.Column>

        <Grid.Column>
          <TimeOnMarketModule />
        </Grid.Column>

        <Grid.Column>
          <RentChangeModule />
        </Grid.Column>
      </Grid>
      {/* Row 1 End */}

      {/* Row 2 */}
      <Grid columns={2} stackable className="equal-height-grid">
        <Grid.Column>
          <GradeSensitivityModule />
        </Grid.Column>

        <Grid.Column>
          <QualityScaleModule />
        </Grid.Column>
      </Grid>
      <RentTableModule />
      {/* Row 2 End */}
    </>
  );


  const tabModules = {
    'overview': [OverviewGrid],
    'supply': [<RentTableModule />],
    'demand': [<LeaseByMonthTableModule />, <SeasonalityModule />],
    'comps': []
  };
  
  // Append general components for all tabs except 'comps'
  if (activeTab !== 'comps') {}

  return (
    <div className="main-container">
      <MapMenu />
      <TabMenu activeTab={activeTab} onTabClick={setActiveTab} />

      <Container fluid className={'dashboardContainer'}>
      <div className="dashboardLeft">
        {tabModules[activeTab].map((moduleComponent, idx) => (
          <React.Fragment>
            {moduleComponent}
          </React.Fragment>
        ))}
      </div>
        <div className={'dashboardRight'}>
          {activeTab !== 'comps' && <Map />}
        </div>
        
        <PropertyViewer />
        <BuildingViewer />
      </Container>
      <Container fluid>
        <Card centered fluid>
          <PropertiesTable />
        </Card>
      </Container>
    </div>
  )
}

export default Dashboard;
