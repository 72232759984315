import React, {createContext, useReducer} from 'react'

export const MapContext = createContext(undefined)
export const MapDispatchContext = createContext(undefined)

export const map_initial_state = {
  lng: -75.165,
  lat: 39.9525,
  zoom: 16,
  data: undefined,
  map_data: undefined,
  map: undefined,
  neighborhoodMap: undefined,
  filter: {},
  highlight_attribute: 'property_score',
  neighborhood: undefined,
  zipcode: undefined,
  custom_polygon: undefined,
  polygonModeOn: false,
  radius: undefined,
  markers: [],
}

const reducer = (state, action) => {
  switch(action.type) {
    case 'SET_LAT':
      return { ...state, lat: action.value }
    case 'SET_LNG':
      return { ...state, lng: action.value }
    case 'SET_ZOOM':
      return { ...state, zoom: action.value }
    case 'SET_DATA':
      return { ...state, data: action.value }
    case 'SET_MAP_DATA':
      return { ...state, map_data: action.value }
    case 'SET_MAP':
      return { ...state, map: action.value }
    case 'SET_NEIGHBORHOOD_MAP':
      return { ...state, neighborhoodMap: action.value }
    case 'SET_FILTER':
      return { ...state, filter: { ...state.filter, ...action.value }}
    case 'SET_HIGHLIGHT_ATTRIBUTE':
      return { ...state, highlight_attribute: action.value }
    case 'SET_NEIGHBORHOOD':
      return { ...state, neighborhood: action.value, custom_polygon: undefined, radius: undefined, polygonModeOn: false, zipcode: undefined }
    case 'SET_ZIPCODE':
      return { ...state, zipcode: action.value, custom_polygon: undefined, radius: undefined, polygonModeOn: false, neighborhood: undefined }
    case 'SET_CUSTOM_POLYGON':
      return { ...state, custom_polygon: action.value, radius: undefined, neighborhood: undefined, zipcode: undefined, polygonModeOn: false }
    case 'POLYGON_MODE_ON':
      return { ...state, polygonModeOn: action.value }
    case 'SET_RADIUS':
      return { ...state, radius: action.value, neighborhood: undefined, custom_polygon: undefined, polygonModeOn: false, zipcode: undefined }
    case 'ADD_MARKER':
      return { ...state, markers: [...state.markers, action.value] }
    case 'CLEAR_MARKERS':
      return { ...state, markers: [] }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const MapContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, map_initial_state)

  return (
    <MapContext.Provider value={state}>
      <MapDispatchContext.Provider value={dispatch}>
        {children}
      </MapDispatchContext.Provider>
    </MapContext.Provider>
  )
}

export default MapContextProvider