const marketData = [
  {
    "neighborhood": "Overbrook",
    "avg_days_on_market": 59.624093473005644,
    "count": 2482
  },
  {
    "neighborhood": "Southwest Germantown",
    "avg_days_on_market": 52.52281616688396,
    "count": 767
  },
  {
    "neighborhood": "East Parkside",
    "avg_days_on_market": 54.50971599402093,
    "count": 669
  },
  {
    "neighborhood": "Germany Hill",
    "avg_days_on_market": 70.92657856093979,
    "count": 681
  },
  {
    "neighborhood": "East Mount Airy",
    "avg_days_on_market": 53.935748462064254,
    "count": 1463
  },
  {
    "neighborhood": "Dearnley Park",
    "avg_days_on_market": 54.7536231884058,
    "count": 276
  },
  {
    "neighborhood": "Wissinoming",
    "avg_days_on_market": 39.00068775790922,
    "count": 1454
  },
  {
    "neighborhood": "Bella Vista",
    "avg_days_on_market": 43.91113434396236,
    "count": 1913
  },
  {
    "neighborhood": "Allegheny West",
    "avg_days_on_market": 63.2,
    "count": 1320
  },
  {
    "neighborhood": "Glenwood",
    "avg_days_on_market": 48.11455108359133,
    "count": 323
  },
  {
    "neighborhood": "Greenwich",
    "avg_days_on_market": 48.24107142857143,
    "count": 448
  },
  {
    "neighborhood": "Francisville",
    "avg_days_on_market": 57.68837651533159,
    "count": 4207
  },
  {
    "neighborhood": "Penrose",
    "avg_days_on_market": 39.4765625,
    "count": 128
  },
  {
    "neighborhood": "Powelton",
    "avg_days_on_market": 68.03563474387528,
    "count": 2694
  },
  {
    "neighborhood": "Garden Court",
    "avg_days_on_market": 54.44489795918367,
    "count": 980
  },
  {
    "neighborhood": "Belmont",
    "avg_days_on_market": 65.48841354723707,
    "count": 561
  },
  {
    "neighborhood": "Pennsport",
    "avg_days_on_market": 44.36476751800917,
    "count": 1527
  },
  {
    "neighborhood": "Stanton",
    "avg_days_on_market": 82.92516339869282,
    "count": 3060
  },
  {
    "neighborhood": "West Oak Lane",
    "avg_days_on_market": 50.991645781119466,
    "count": 1197
  },
  {
    "neighborhood": "Burholme",
    "avg_days_on_market": 42.309734513274336,
    "count": 113
  },
  {
    "neighborhood": "Fern Rock",
    "avg_days_on_market": 70.88854489164086,
    "count": 323
  },
  {
    "neighborhood": "Dunlap",
    "avg_days_on_market": 65.33333333333333,
    "count": 423
  },
  {
    "neighborhood": "Melrose Park Gardens",
    "avg_days_on_market": 32.91596638655462,
    "count": 119
  },
  {
    "neighborhood": "Franklinville",
    "avg_days_on_market": 56.98837209302326,
    "count": 516
  },
  {
    "neighborhood": "Hawthorne",
    "avg_days_on_market": 52.243626062322946,
    "count": 1765
  },
  {
    "neighborhood": "Bartram Village",
    "avg_days_on_market": 45.976470588235294,
    "count": 85
  },
  {
    "neighborhood": "East Poplar",
    "avg_days_on_market": 62.48299319727891,
    "count": 735
  },
  {
    "neighborhood": "Bustleton",
    "avg_days_on_market": 45.06306306306306,
    "count": 888
  },
  {
    "neighborhood": "Cedarbrook",
    "avg_days_on_market": 48.35576923076923,
    "count": 312
  },
  {
    "neighborhood": "Ludlow",
    "avg_days_on_market": 62.62601156069364,
    "count": 1730
  },
  {
    "neighborhood": "Bridesburg",
    "avg_days_on_market": 37.22962962962963,
    "count": 135
  },
  {
    "neighborhood": "East Falls",
    "avg_days_on_market": 59.05689593086064,
    "count": 2777
  },
  {
    "neighborhood": "North Central",
    "avg_days_on_market": 98.23456433929601,
    "count": 10398
  },
  {
    "neighborhood": "Grays Ferry",
    "avg_days_on_market": 54.405210420841684,
    "count": 2495
  },
  {
    "neighborhood": "Frankford",
    "avg_days_on_market": 48.3941351888668,
    "count": 2012
  },
  {
    "neighborhood": "Chestnut Hill",
    "avg_days_on_market": 55.11611675126903,
    "count": 1576
  },
  {
    "neighborhood": "Olney",
    "avg_days_on_market": 42.26229508196721,
    "count": 1159
  },
  {
    "neighborhood": "West Mount Airy",
    "avg_days_on_market": 65.61960784313726,
    "count": 1530
  },
  {
    "neighborhood": "Somerton",
    "avg_days_on_market": 34.33478893740902,
    "count": 687
  },
  {
    "neighborhood": "Mantua",
    "avg_days_on_market": 78.35850422195416,
    "count": 4145
  },
  {
    "neighborhood": "Modena",
    "avg_days_on_market": 40.25994318181818,
    "count": 704
  },
  {
    "neighborhood": "Roxborough Park",
    "avg_days_on_market": 54.220338983050844,
    "count": 177
  },
  {
    "neighborhood": "Morrell Park",
    "avg_days_on_market": 47.75457875457875,
    "count": 819
  },
  {
    "neighborhood": "Riverfront",
    "avg_days_on_market": 70.3643031784841,
    "count": 409
  },
  {
    "neighborhood": "Pennypack",
    "avg_days_on_market": 39.81024096385542,
    "count": 664
  },
  {
    "neighborhood": "Yorktown",
    "avg_days_on_market": 59.946360153256705,
    "count": 261
  },
  {
    "neighborhood": "Parkwood Manor",
    "avg_days_on_market": 37.31920199501247,
    "count": 401
  },
  {
    "neighborhood": "Aston-Woodbridge",
    "avg_days_on_market": 30.38364779874214,
    "count": 159
  },
  {
    "neighborhood": "Pennypack Woods",
    "avg_days_on_market": 27.842105263157894,
    "count": 57
  },
  {
    "neighborhood": "Millbrook",
    "avg_days_on_market": 62.92857142857143,
    "count": 70
  },
  {
    "neighborhood": "Academy Gardens",
    "avg_days_on_market": 34.21232876712329,
    "count": 146
  },
  {
    "neighborhood": "Torresdale",
    "avg_days_on_market": 39.509186351706035,
    "count": 381
  },
  {
    "neighborhood": "Lexington Park",
    "avg_days_on_market": 35.67479674796748,
    "count": 123
  },
  {
    "neighborhood": "Rhawnhurst",
    "avg_days_on_market": 32.44407064760303,
    "count": 1189
  },
  {
    "neighborhood": "Fox Chase",
    "avg_days_on_market": 41.60200668896321,
    "count": 598
  },
  {
    "neighborhood": "Oxford Circle",
    "avg_days_on_market": 29.631201044386422,
    "count": 1532
  },
  {
    "neighborhood": "Summerdale",
    "avg_days_on_market": 44.02506963788301,
    "count": 359
  },
  {
    "neighborhood": "Lawndale",
    "avg_days_on_market": 42.52690863579474,
    "count": 799
  },
  {
    "neighborhood": "Sharswood",
    "avg_days_on_market": 71.64652567975831,
    "count": 662
  },
  {
    "neighborhood": "Passyunk Square",
    "avg_days_on_market": 49.09431021044427,
    "count": 2566
  },
  {
    "neighborhood": "Northwood",
    "avg_days_on_market": 41.16888888888889,
    "count": 225
  },
  {
    "neighborhood": "Holmesburg",
    "avg_days_on_market": 36.94681647940075,
    "count": 1335
  },
  {
    "neighborhood": "Mayfair",
    "avg_days_on_market": 28.64790996784566,
    "count": 1866
  },
  {
    "neighborhood": "Tacony",
    "avg_days_on_market": 40.017781541066896,
    "count": 1181
  },
  {
    "neighborhood": "Hartranft",
    "avg_days_on_market": 92.02701822916667,
    "count": 3072
  },
  {
    "neighborhood": "Andorra",
    "avg_days_on_market": 52.95,
    "count": 80
  },
  {
    "neighborhood": "Manayunk",
    "avg_days_on_market": 53.1760014179369,
    "count": 5642
  },
  {
    "neighborhood": "Upper Roxborough",
    "avg_days_on_market": 62.003679175864605,
    "count": 1359
  },
  {
    "neighborhood": "Roxborough",
    "avg_days_on_market": 57.0382928968138,
    "count": 3421
  },
  {
    "neighborhood": "Nicetown",
    "avg_days_on_market": 48.8177570093458,
    "count": 214
  },
  {
    "neighborhood": "Wissahickon",
    "avg_days_on_market": 47.84240246406571,
    "count": 1948
  },
  {
    "neighborhood": "Dickinson Narrows",
    "avg_days_on_market": 41.10162601626016,
    "count": 1230
  },
  {
    "neighborhood": "East Germantown",
    "avg_days_on_market": 49.593933463796475,
    "count": 1022
  },
  {
    "neighborhood": "Germantown - Morton",
    "avg_days_on_market": 55.20738137082601,
    "count": 569
  },
  {
    "neighborhood": "Old Kensington",
    "avg_days_on_market": 66.6601353758461,
    "count": 2807
  },
  {
    "neighborhood": "West Central Germantown",
    "avg_days_on_market": 68.58481152993348,
    "count": 1804
  },
  {
    "neighborhood": "Germantown - Penn Knox",
    "avg_days_on_market": 52.398305084745765,
    "count": 236
  },
  {
    "neighborhood": "Germantown - Westside",
    "avg_days_on_market": 56.474603174603175,
    "count": 630
  },
  {
    "neighborhood": "Ogontz",
    "avg_days_on_market": 53.23312883435583,
    "count": 1141
  },
  {
    "neighborhood": "Wister",
    "avg_days_on_market": 49.14691943127962,
    "count": 422
  },
  {
    "neighborhood": "Tioga",
    "avg_days_on_market": 63.832663989290495,
    "count": 1494
  },
  {
    "neighborhood": "East Oak Lane",
    "avg_days_on_market": 58.68382352941177,
    "count": 408
  },
  {
    "neighborhood": "Feltonville",
    "avg_days_on_market": 37.55726872246696,
    "count": 454
  },
  {
    "neighborhood": "Fairhill",
    "avg_days_on_market": 43.55652173913043,
    "count": 115
  },
  {
    "neighborhood": "Richmond",
    "avg_days_on_market": 45.72514466070489,
    "count": 3802
  },
  {
    "neighborhood": "Hunting Park",
    "avg_days_on_market": 45.39761431411531,
    "count": 503
  },
  {
    "neighborhood": "Juniata Park",
    "avg_days_on_market": 48.44277108433735,
    "count": 332
  },
  {
    "neighborhood": "Brewerytown",
    "avg_days_on_market": 62.49748110831234,
    "count": 3176
  },
  {
    "neighborhood": "Harrowgate",
    "avg_days_on_market": 54.311688311688314,
    "count": 1540
  },
  {
    "neighborhood": "Upper Kensington",
    "avg_days_on_market": 46.748055987558324,
    "count": 1286
  },
  {
    "neighborhood": "McGuire",
    "avg_days_on_market": 38.415929203539825,
    "count": 113
  },
  {
    "neighborhood": "West Kensington",
    "avg_days_on_market": 67.79121789560895,
    "count": 2414
  },
  {
    "neighborhood": "Fishtown - Lower Kensington",
    "avg_days_on_market": 49.38272570802691,
    "count": 6391
  },
  {
    "neighborhood": "Northern Liberties",
    "avg_days_on_market": 58.300474088605526,
    "count": 6117
  },
  {
    "neighborhood": "Logan",
    "avg_days_on_market": 46.21927115503397,
    "count": 1619
  },
  {
    "neighborhood": "Society Hill",
    "avg_days_on_market": 58.428061831153386,
    "count": 3364
  },
  {
    "neighborhood": "Old City",
    "avg_days_on_market": 69.03795811518324,
    "count": 6876
  },
  {
    "neighborhood": "Chinatown",
    "avg_days_on_market": 70.15233415233415,
    "count": 407
  },
  {
    "neighborhood": "Center City East",
    "avg_days_on_market": 77.0021645021645,
    "count": 462
  },
  {
    "neighborhood": "Washington Square West",
    "avg_days_on_market": 57.66986899563319,
    "count": 9160
  },
  {
    "neighborhood": "Fairmount",
    "avg_days_on_market": 49.60872110038045,
    "count": 3417
  },
  {
    "neighborhood": "Spring Garden",
    "avg_days_on_market": 55.02378212504795,
    "count": 5214
  },
  {
    "neighborhood": "Logan Square",
    "avg_days_on_market": 60.44896465939855,
    "count": 13667
  },
  {
    "neighborhood": "Rittenhouse",
    "avg_days_on_market": 59.73102781136638,
    "count": 20675
  },
  {
    "neighborhood": "Fitler Square",
    "avg_days_on_market": 44.359267734553775,
    "count": 874
  },
  {
    "neighborhood": "Graduate Hospital",
    "avg_days_on_market": 49.361518987341775,
    "count": 7900
  },
  {
    "neighborhood": "Point Breeze",
    "avg_days_on_market": 53.395561817541385,
    "count": 5678
  },
  {
    "neighborhood": "Queen Village",
    "avg_days_on_market": 53.673004897724,
    "count": 3471
  },
  {
    "neighborhood": "Lower Moyamensing",
    "avg_days_on_market": 44.28072625698324,
    "count": 2148
  },
  {
    "neighborhood": "Whitman",
    "avg_days_on_market": 42.06912991656734,
    "count": 839
  },
  {
    "neighborhood": "Southwest Schuylkill",
    "avg_days_on_market": 54.754279959718026,
    "count": 993
  },
  {
    "neighborhood": "Clearview",
    "avg_days_on_market": 43.9375,
    "count": 64
  },
  {
    "neighborhood": "West Parkside",
    "avg_days_on_market": 74.609375,
    "count": 64
  },
  {
    "neighborhood": "Mill Creek",
    "avg_days_on_market": 82.67405063291139,
    "count": 948
  },
  {
    "neighborhood": "Paschall",
    "avg_days_on_market": 45.93707250341997,
    "count": 731
  },
  {
    "neighborhood": "Spruce Hill",
    "avg_days_on_market": 60.95704697986577,
    "count": 4470
  },
  {
    "neighborhood": "West Powelton",
    "avg_days_on_market": 71.68973666441593,
    "count": 2962
  },
  {
    "neighborhood": "Kingsessing",
    "avg_days_on_market": 49.287786616752456,
    "count": 2137
  },
  {
    "neighborhood": "University City",
    "avg_days_on_market": 79.53557046979866,
    "count": 5960
  },
  {
    "neighborhood": "Haddington",
    "avg_days_on_market": 49.54374017810372,
    "count": 1909
  },
  {
    "neighborhood": "Carroll Park",
    "avg_days_on_market": 50.71782650142993,
    "count": 1049
  },
  {
    "neighborhood": "Haverford North",
    "avg_days_on_market": 68.3205574912892,
    "count": 287
  },
  {
    "neighborhood": "Wynnefield Heights",
    "avg_days_on_market": 62.28414755732802,
    "count": 1003
  },
  {
    "neighborhood": "Cobbs Creek",
    "avg_days_on_market": 50.62668419323037,
    "count": 3043
  },
  {
    "neighborhood": "Walnut Hill",
    "avg_days_on_market": 69.30029440628067,
    "count": 2038
  },
  {
    "neighborhood": "Cedar Park",
    "avg_days_on_market": 62.37979584653291,
    "count": 2841
  },
  {
    "neighborhood": "Woodland Terrace",
    "avg_days_on_market": 54.18254674977738,
    "count": 1123
  },
  {
    "neighborhood": "Callowhill",
    "avg_days_on_market": 74.36685032139577,
    "count": 2178
  },
  {
    "neighborhood": "West Poplar",
    "avg_days_on_market": 79.1203125,
    "count": 2560
  },
  {
    "neighborhood": "Strawberry Mansion",
    "avg_days_on_market": 53.82706310679612,
    "count": 1648
  },
  {
    "neighborhood": "Packer Park",
    "avg_days_on_market": 52.605448154657296,
    "count": 1138
  },
  {
    "neighborhood": "Wynnefield",
    "avg_days_on_market": 70.6795195954488,
    "count": 1582
  },
  {
    "neighborhood": "West Passyunk",
    "avg_days_on_market": 54.1085676913015,
    "count": 1529
  },
  {
    "neighborhood": "East Passyunk",
    "avg_days_on_market": 42.36884830035515,
    "count": 1971
  },
  {
    "neighborhood": "Newbold",
    "avg_days_on_market": 45.48335552596538,
    "count": 1502
  },
  {
    "neighborhood": "Stadium District",
    "avg_days_on_market": 44.03560830860534,
    "count": 337
  },
  {
    "neighborhood": "East Kensington",
    "avg_days_on_market": 60.754611903933174,
    "count": 2873
  },
  {
    "neighborhood": "Elmwood",
    "avg_days_on_market": 43.566968781470294,
    "count": 993
  },
  {
    "neighborhood": "Girard Estates",
    "avg_days_on_market": 53.139351446099916,
    "count": 1141
  },
  {
    "neighborhood": "Eastwick",
    "avg_days_on_market": 78.91666666666667,
    "count": 168
  }
]

export default marketData