import React from 'react'
import ReactDOM from 'react-dom/client'
import MapContextProvider from "./contexts/MapContext";
import SearchProvider from "./contexts/SearchContext";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ViewerContextProvider from "./contexts/ViewerContext";
import Home from "./routes/Home/Home"
import Dashboard from "./routes/Dashboard/Dashboard"

import 'semantic-ui-less/semantic.less'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import Landing from "./routes/Landing/Landing";

const root = ReactDOM.createRoot(document.getElementById('root'))

const router = createBrowserRouter([
  {
    path: '/',
    element: <Landing />
  },
  {
    path: '/dashboard',
    element: <Dashboard />
  }
])


root.render(
  <React.StrictMode>
    <ViewerContextProvider>
      <MapContextProvider>
        <SearchProvider>
          <RouterProvider router={router} />
        </SearchProvider>
      </MapContextProvider>
    </ViewerContextProvider>
  </React.StrictMode>
)