import {Table, Pagination, Dropdown} from "semantic-ui-react"
import {MapContext} from "../../../contexts/MapContext"
import { SearchContext } from "../../../contexts/SearchContext"
import {useContext, useEffect, useReducer, useMemo, useState} from "react"
import { sortAndSliceData, reducer, preprocessProperties } from './functions'
import './PropertiesTable.scss'
import _ from "lodash"
import PropertyRow from "./PropertyRow"

const PAGE_SIZE = 10

export const PropertiesTable = () => {
  const [state, dispatch] = useReducer(reducer, {
    column: null,
    direction: null,
    page: 1,
    pageSize: PAGE_SIZE,
  })

  const { column, direction, page, pageSize } = state

  const mapContext = useContext(MapContext)
  const searchContext = useContext(SearchContext)
  const [updatedProperties, setUpdatedProperties] = useState([])

  useEffect(() => {
    if (mapContext.data?.properties) {
      let props = preprocessProperties({
        properties: mapContext.data.properties,
        searchContext
      })

      // Group by building, convert to object
      let buildings =  _.groupBy(props, 'building_id')
      let final_props = []
      for(let building_id in buildings) {
        if(buildings[building_id].length == 1) {
          final_props.push(buildings[building_id][0])
        }
        else {
          final_props.push({
            building_id,
            properties: buildings[building_id]
          })
        }
      }

      setUpdatedProperties(final_props)
    } else {
      setUpdatedProperties([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapContext.data, searchContext.selectedData])

  const totalPages = updatedProperties ? Math.ceil(updatedProperties.length / pageSize) : 0;

  const handleSort = (column) => {
    const sortedData = sortAndSliceData({
      fullData: updatedProperties,
      column, direction, page, pageSize
    })
    dispatch({ type: 'CHANGE_SORT', column, sortedData })
  }

  const sortedAndSlicedData = useMemo(() => {
    const fullData = updatedProperties || []
    return sortAndSliceData({ fullData, column, direction, page, pageSize })
  }, [updatedProperties, column, direction, page, pageSize])

  // Reset page when data/map view change
  useEffect(() => {
    dispatch({type: 'SET_PAGE', page: 1})
  }, [updatedProperties])

  return (
    <div className="properties-table-container">
    <div>
      <h2>Nearby Comps</h2>
    </div>
    <Table selectable sortable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Address</Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'grade' ? direction : null}
            onClick={() => handleSort('grade')}
            textAlign={'center'}
          >
            Grade
          </Table.HeaderCell>
          { searchContext.selectedData &&
          (
            <Table.HeaderCell
              sorted={column === 'distance' ? direction : null}
              onClick={() => handleSort('distance')}
              textAlign={'center'}
            >
              Distance
            </Table.HeaderCell>
          )}
          <Table.HeaderCell
            sorted={column === 'lease_price' ? direction : null}
            onClick={() => handleSort('lease_price')}
            textAlign={'center'}
          >
            Rent
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'days_to_lease' ? direction : null}
            onClick={() => handleSort('days_to_lease')}
            textAlign={'center'}
          >
            Days To Lease
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'last_lease_date' ? direction : null}
            onClick={() => handleSort('last_lease_date')}
            textAlign={'center'}
          >
            List Date
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'beds' ? direction : null}
            onClick={() => handleSort('beds')}
            textAlign={'center'}
          >
            Beds
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'baths' ? direction : null}
            onClick={() => handleSort('baths')}
            textAlign={'center'}
          >
            Baths
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'sqft' ? direction : null}
            onClick={() => handleSort('sqft')}
            textAlign={'center'}
          >
            SQFT
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'unit_type' ? direction : null}
            onClick={() => handleSort('unit_type')}
          >
            Type
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {sortedAndSlicedData.map(property => (
          <PropertyRow propertyOrBuilding={property} />
        ))}
      </Table.Body>

      <Table.Footer>

      </Table.Footer>
    </Table>
    <div className="pagination-wrapper">
        <div className="rows-per-page-selector">
          <span style={{marginRight: '1rem'}}>Rows per page:</span>
          <Dropdown
            inline
            options={[
              { key: 10, text: '10', value: 10 },
              { key: 20, text: '20', value: 20 },
              { key: 50, text: '50', value: 50 },
              { key: 100, text: '100', value: 100 },
            ]}
            defaultValue={PAGE_SIZE}
            onChange={(e, { value }) => {
              dispatch({type: 'SET_PAGE_SIZE', pageSize: value})
              dispatch({type: 'RESET_PAGE'})
            }}
          />
        </div>

        <span className="page-range-display">
          {((page - 1) * PAGE_SIZE) + 1} - {Math.min(page * PAGE_SIZE, updatedProperties.length || 0)} of {updatedProperties.length || 0}
        </span>
        
        <Pagination
          className='custom-pagination'
          boundaryRange={0}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          siblingRange={0}
          prevItem={{ content: '<', disabled: page === 1, icon: true  }}
          nextItem={{ content: '>', disabled: page === totalPages, icon: true }}
          totalPages={totalPages}
          onPageChange={(e, { activePage }) => dispatch({type: 'SET_PAGE', page: activePage})}
        />
      </div>
    </div>
  )
}
