import getGradeFromScore from "../../../util/getGradeFromScore";
import {getColorFromGrade} from "../../../util/colors";
import {useContext, useEffect, useState} from "react";
import {ViewerDispatchContext} from "../../../contexts/ViewerContext";
import {Label, Table} from "semantic-ui-react";
import {formatNumberWithCommas, formatTypeToHumanFriendly} from "../../../util";
import moment from "moment";
import axios from "axios";
import {parseAddress} from "parse-address"

const PropertyRowWrapper = ({ propertyOrBuilding }) => {
  const viewerDispatch = useContext(ViewerDispatchContext)

  if(propertyOrBuilding.properties) {
    return <BuildingRow
      building={propertyOrBuilding}
      viewerDispatch={viewerDispatch}
    />
  }
  else {
    return <PropertyRow
      property={propertyOrBuilding}
      viewerDispatch={viewerDispatch}
      inBuilding={false}
    />
  }
}

const BuildingRow = ({ building, viewerDispatch }) => {
  const [ buildingDetail, setBuildingDetail ] = useState({})

  useEffect(() => {
    axios.post(process.env.REACT_APP_API_URL + 'getBuilding', {
      id: building.building_id
    })
    .then(raw_data => {
      setBuildingDetail(raw_data.data)
      viewerDispatch({ type: 'ADD_BUILDING_TO_CACHE', value: raw_data.data })
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building])

  const handleRowClick = () => {
    // TODO ALL BUILDING DATA
    viewerDispatch({ type: 'SET_BUILDING_ID', value: building.building_id })
    viewerDispatch({ type: 'SET_BUILDING_OPEN', value: true })
  }

  let displayUnitCount = buildingDetail?.rental_license?.unit_count || building.properties.length

  return (
    <>
      <Table.Row className="property-row" onClick={handleRowClick}>
        <Table.Cell>
          {buildingDetail.address}
          <Label>
            {displayUnitCount} units
          </Label>
        </Table.Cell>
        <Table.Cell colSpan={8} />
      </Table.Row>
      {
        building.properties.slice(0,3).map(property => {
          return <PropertyRow
            property={property}
            viewerDispatch={viewerDispatch}
            inBuilding={true}
          />
        })
      }
    </>

  )
}

const PropertyRow = ({ property, viewerDispatch, inBuilding }) => {
  let grade = getGradeFromScore(property.property_score)
  let color = getColorFromGrade(grade)
  let last_lease = property.leases[property.leases.length - 1]

  const handleRowClick = () => {
    viewerDispatch({ type: 'SET_PROPERTY_ID', value: property.id })
    viewerDispatch({ type: 'SET_PROPERTY_OPEN', value: true})
  }

  let display_address = property.address
  if(inBuilding) {
    let unit_no = parseAddress(display_address)?.sec_unit_num
    if(unit_no) { display_address = `Unit ${unit_no}` }
  }

  return (
    <Table.Row className="property-row" onClick={handleRowClick}>
      <Table.Cell
      textAlign={ inBuilding ? 'center' : 'left' }
      >{display_address}</Table.Cell>
      <Table.Cell className="grade-bubble">
        <div style={{backgroundColor: color}}>
          <span>
            {grade}
          </span>
        </div>
      </Table.Cell>
      { property.distance && <Table.Cell textAlign={'center'}>{property.distance} mi</Table.Cell> }
      <Table.Cell textAlign={'center'}>${formatNumberWithCommas(last_lease.lease_price)}</Table.Cell>
      <Table.Cell textAlign={'center'}>{property.days_to_lease}</Table.Cell>
      <Table.Cell textAlign={'center'}>{moment(last_lease.lease_date).format('MMM D, YYYY')}</Table.Cell>
      <Table.Cell textAlign={'center'}>{property.beds}</Table.Cell>
      <Table.Cell textAlign={'center'}>{property.baths}</Table.Cell>
      <Table.Cell textAlign={'center'}>
        {
          property.sqft ? formatNumberWithCommas(property.sqft) : '-'
        }
      </Table.Cell>
      <Table.Cell>{formatTypeToHumanFriendly(property.unit_type)}</Table.Cell>
    </Table.Row>
  )
}

export default PropertyRowWrapper