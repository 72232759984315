import { formatNumber } from "../../../util";

export const CATEGORIES = [
  "Not sensitive",
  "Slightly sensitive",
  "Normally sensitive",
  "Very sensitive",
  "Highly sensitive",
];

export const getCategory = (score) => {
  if (score < 0.2) return CATEGORIES[0];
  if (score < 0.4) return CATEGORIES[1];
  if (score < 0.6) return CATEGORIES[2];
  if (score < 0.8) return CATEGORIES[3];
  return CATEGORIES[4];
};

export const getBinEdges = () => {
  const N_GROUPS = 10;
  const binEdges = Array.from({ length: N_GROUPS + 1 }, (_, index) =>
    formatNumber(index * (1 / N_GROUPS) / 2)
  );
  const binCounts = Array(N_GROUPS).fill(0);

  return {
    binEdges,
    binCounts,
  };
};

export const getBin = (score) => {
  const { binEdges } = getBinEdges();
  for (let i = 0; i < binEdges.length - 1; i++) {
    if (score >= binEdges[i] && score < binEdges[i + 1]) {
      return `${binEdges[i]} - ${binEdges[i + 1]}`;
    }
  }
  return null;
};

export const calculateGradeCorrelation = (properties) => {
  let n = properties.length;

  let sum_x = 0, sum_y = 0, sum_xy = 0, sum_x2 = 0, sum_y2 = 0;

  properties.forEach(p => {
    let x = p.lease_price;
    let y = p.property_score;

    sum_x += x;
    sum_y += y;
    sum_xy += x * y;
    sum_x2 += x * x;
    sum_y2 += y * y;
  });

  let numerator = n * sum_xy - sum_x * sum_y;
  let denominator = Math.sqrt((n * sum_x2 - sum_x * sum_x) * (n * sum_y2 - sum_y * sum_y));

  return numerator / denominator;
}

export const processNeighborhoodData = (neighborhoodData) => {
  const { binEdges, binCounts } = getBinEdges();

  neighborhoodData.forEach(data => {
    for (let i = 0; i < binEdges.length - 1; i++) {
      if (data.correlation >= binEdges[i] && data.correlation < binEdges[i+1]) {
        binCounts[i]++;
      }
    }
  });  
  const binnedData = binCounts.map((count, idx) => ({
    bin: `${binEdges[idx]} - ${binEdges[idx + 1]}`,
    count
  }))

  // Calculate the mean and standard deviation
  const mean = neighborhoodData.reduce((acc, data) => acc + data.correlation, 0) / neighborhoodData.length;
  const variance = neighborhoodData.reduce((acc, data) => acc + Math.pow(data.correlation - mean, 2), 0) / neighborhoodData.length;
  const stdDev = Math.sqrt(variance);

  const getMaxCount = Math.max(...binnedData.map(d => d.count));

  // Calculate the density values using the PDF for normal distribution
  let maxDensity = 0;  // To keep track of the highest density value
  binnedData.forEach(data => {
    const x = (parseFloat(data.bin.split(" - ")[1]) + parseFloat(data.bin.split(" - ")[0])) / 2;
    const density = (1 / (stdDev * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));
    
    data.density = density;
    if (density > maxDensity) {
      maxDensity = density;
    }
  });

  // Scaling factor
  const scale = getMaxCount / maxDensity;

  // Apply the scaling factor to all the density values
  binnedData.forEach(data => {
    data.density *= scale;
  });

  return binnedData
}