import moment from "moment";
import {getGrade} from "../../../util";

export const extractLeases = (properties) => {
  let leases = []
  for(let prop of properties) {
    for(let lease of prop.leases) {
      leases.push({
        grade: getGrade(prop.property_score),
        normal_score: prop.property_score,
        x: new Date(lease.lease_date).valueOf(),
        y: lease.lease_price
      })
    }
  }
  return leases
}

export const filterOutliers = (leases, N) => {
  // Calculate mean of y values
  const mean = leases.reduce((acc, lease) => acc + lease.y, 0) / leases.length;

  // Calculate standard deviation of y values
  const variance = leases.reduce((acc, lease) => acc + Math.pow(lease.y - mean, 2), 0) / leases.length;
  const stdDev = Math.sqrt(variance);

  // Filter leases where y is more than N standard deviations from the mean
  return leases.filter(lease => {
    return Math.abs(lease.y - mean) <= N * stdDev;
  });
}

export const movingAverage = (leases, graphTicks) => {
  const startTick = graphTicks[0]
  const endTick = graphTicks[graphTicks.length - 1]

  // Define number of intervals and calculate the step size
  const intervals = 20;
  const stepSize = (endTick - startTick) / (intervals - 1);

  let ticks = [];
  for (let i = 0; i < intervals; i++) {
    ticks.push(startTick + (i * stepSize));
  }

  // Make sure the leases are sorted by x values.
  leases.sort((a, b) => a.x - b.x);

  let result = [];
  let leaseIndex = 0;

  for (let i = 0; i < ticks.length; i++) {
    let sum = 0;
    let count = 0;

    while (leaseIndex < leases.length && (i === ticks.length - 1 || leases[leaseIndex].x < ticks[i] + stepSize)) {
      sum += leases[leaseIndex].y;
      count++;
      leaseIndex++;
    }

    // If we have leases within this tick interval, calculate average and add to result
    if (count > 0) {
      result.push({
        x: ticks[i],
        y: sum / count
      });
    }
  }

  return result;
}

export const chartMeta = ({ graphParams }) => {
  let domain = [
    moment().subtract(graphParams.range, 'years').valueOf(),
    moment().valueOf()
  ]
  let ticks = [domain[0]]

  if(graphParams.range >= 5) {
    for(let i = 0; i < graphParams.range; i++) {
      let nextY = moment(ticks[ticks.length - 1]).add(1, 'year')
      ticks.push(nextY.valueOf())
    }
  }
  else {
    for(let i = 0; i < graphParams.range * 4; i++) {
      let nextQ = moment(ticks[ticks.length - 1]).add(1, 'quarter')
      ticks.push(nextQ.valueOf())
    }
  }
  return { domain, ticks }
}