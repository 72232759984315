const marketData = [
  { month: 1, leases: 17507, percent: 0.06537683075291464 },
  { month: 2, leases: 18337, percent: 0.06847632064409641 },
  { month: 3, leases: 24518, percent: 0.0915581845204753 },
  { month: 4, leases: 28862, percent: 0.10778009305938324 },
  { month: 5, leases: 30807, percent: 0.11504335551522485 },
  { month: 6, leases: 32430, percent: 0.12110416526629472 },
  { month: 7, leases: 31289, percent: 0.11684330024721233 },
  { month: 8, leases: 22978, percent: 0.0858073237585236 },
  { month: 9, leases: 16075, percent: 0.06002927710933357 },
  { month: 10, leases: 15904, percent: 0.05939070750524673 },
  { month: 11, leases: 14976, percent: 0.055925253747395307 },
  { month: 12, leases: 14103, percent: 0.05266518787389931 }
]


export default marketData