import { ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line, LineChart, ResponsiveContainer, Area, AreaChart } from 'recharts'
import { Card, Message, Button } from 'semantic-ui-react'
import {useContext, useEffect, useState} from "react"
import {MapContext} from "../../../contexts/MapContext"
import moment from 'moment'

import './SeasonalityModule.scss'
import marketData from'./seasonality_data'
import AIAnalyst from "../AIAnalyst/AIAnalyst";

const GRID_COLOR = '#ECE9F1'
const LINE_COLOR = '#4B5DC5'

const leasesByMonth = (properties) => {
  let monthsData = Array.from({ length: 12 }, (_, i) => ({
    month: i + 1,
    leases: 0,
    percent: 0
  }))

  properties.forEach(property => {
    property.leases.forEach(lease => {
      const leaseDate = new Date(lease.lease_date)
      monthsData[leaseDate.getMonth()].leases += 1
    })
  })

  let totalLeases = monthsData.reduce((count, month) => {
    return month.leases + count
  }, 0)

  return monthsData.map(month => {
    month.percent = month.leases / totalLeases
    return month
  })
}

const generateStats = ({data, marketData}) => {
  if (!data?.properties) return null;

  let new_stats = leasesByMonth(data.properties);
  
  for(let i in marketData) {
      new_stats[i].percentCity = marketData[i].percent;
  }
  
  return new_stats;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    let data = payload[0].payload

    return (
      <div className='tooltip'>
        <p className='tooltip-primary-text'>{data.leases} Leases Signed</p>
        <p className='tooltip-secondary-text'>{Math.ceil(data.percent * 100)}% of all leases here</p>
        <p className='tooltip-secondary-text'>{Math.ceil(data.percentCity * 100)}% of city leases this month</p>
      </div>
    )
  }
  return null
}

export const SeasonalityModule = () => {
  const state = useContext(MapContext)
  const [stats, setStats] = useState({})

  useEffect(() => {
    const result = generateStats({data: state.data, marketData});
    if (result) {
        setStats(result);
    }
  }, [state.data]);


  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>Leasing Seasonality</Card.Header>
        <Card.Meta>Lease signing date by month</Card.Meta>
        <AIAnalyst data={stats} static_data={marketData} module_name={'seasonality'} />
        <ResponsiveContainer height={300}>
          <ComposedChart data={stats}>
            <defs>
              <linearGradient id='fill' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor='#4B5DC5' stopOpacity={0.2} />
                <stop offset='95%' stopColor='#FFFFFF' stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} stroke={GRID_COLOR} />
            <YAxis
              tickLine={false}
              tickMargin={15}
              axisLine={false}
              tickFormatter={(v) => Math.floor(v * 100) + '%'}
            />
            <XAxis
              tickLine={false}
              minTickGap={25}
              tickMargin={15}
              axisLine={false}
              dataKey={'month'}
              interval={1}
              tickFormatter={(v) => moment().month(v - 1).format('MMM')}
            />
            <Tooltip content={ <CustomTooltip /> }/>
            <Area
              type="linear"
              dataKey={'percent'}
              stroke={LINE_COLOR}
              strokeWidth={2}
              dot={false}
              fillOpacity={1}
              fill='url(#fill)'
            />
            <Line
              type="monotone"
              dataKey={'percentCity'}
              stroke={LINE_COLOR}
              strokeWidth={2}
              dot={false}
              strokeDasharray={'4'}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Card.Content>
    </Card>
  )
}
