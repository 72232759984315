import {Search,Label,Icon} from "semantic-ui-react";
import { useLocation } from 'react-router-dom';
import { queryStringToState } from "../../dashboard/Map/functions";
import { MapDispatchContext } from "../../../contexts/MapContext";
import { SearchContext, SearchDispatchContext } from "../../../contexts/SearchContext";
import {useCallback, useEffect, useRef, useContext} from "react";
import { validateNeighborhood } from "../../dashboard/NeighborhoodSelector";
import { validateZipcode } from "../../dashboard/ZipCodeSelector";
import axios from 'axios'
import parser from 'parse-address'

import './SearchBar.scss'
import { extractZipcode } from "../../../util";

const searchForArea = async ({searchValue, dispatch}) => {
  if(searchValue.length <= 3) return;

  let resp = await axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + searchValue + '.json', {
    params: {
      access_token: process.env.REACT_APP_MAPBOX_TOKEN,
      autocomplete: true,
      bbox: [-75.293058,39.893853,-74.991277,40.064592],
      proximity: '-75.163845,39.952720',
      types: 'address,neighborhood,postcode'
    }
  });

  let results = resp.data.features
  .filter(f => {
    return (
      f.place_name.indexOf('Philadelphia') != -1 &&
      !(f.place_type == 'address' && !parser.parseLocation(f.place_name).number)
    )

  })
  .map(f => {
    let formatted_label
    if(f.place_type == 'neighborhood') {
      formatted_label = f.place_name.substring(0, f.place_name.indexOf(','))
    }
    else if(f.place_type == 'address') {
      let addr = parser.parseLocation(f.place_name)
      formatted_label = addr.number + ' ' + addr.street + ' ' + addr.type + ', ' + addr.zip
    }
    else if(f.place_type == 'postcode') {
      formatted_label = extractZipcode(f)
    }
    return {
      title: formatted_label,
      data: f,
      renderer: ({ title, data }) => (
        <div key='content' className='content'>
          <div className='title'>
            {title}
            {data.place_type == 'neighborhood' && validateNeighborhood(data) &&<Label className="searchbar-label" size={'small'} content={'Neighborhood'} />}
            {data.place_type == 'postcode' && validateZipcode(data) &&<Label className="searchbar-label" size={'small'} content={'Zip code'} />}
          </div>
        </div>
      )
    }
  })

  dispatch({ type: 'RESULTS', results })
  return results
}

const SearchBar = ({ resultCallback, searchCallback }) => {
  const state = useContext(SearchContext)
  const dispatch = useContext(SearchDispatchContext)
  const timeoutRef = useRef()
  const { loading, results, value } = state
  const location = useLocation();
  const contextDispatch = useContext(MapDispatchContext)

  useEffect(() => {
    (async () => {
      let queryState = queryStringToState(location.search);
      if(queryState.searchArea) {
        try {
          let results = await searchForArea({
            searchValue: queryState.searchArea,
            dispatch
          });
          if(results && results.length) {
            handleResultSelect(null, { result: results[0] });
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const handleResultSelect = (e, { result }) => {
    dispatch({ type: 'SELECT', value: result.title });
    dispatch({ type: 'SELECT_DATA', data: result.data });
    if (resultCallback) resultCallback(e, { result: result.data });
  };

  const handleClearSearch = () => {
    dispatch({ type: 'RESET' })

    // Context dispatch
    contextDispatch({ type: 'SET_RADIUS', value: undefined });
    contextDispatch({ type: 'SET_CUSTOM_POLYGON', value: undefined });
    contextDispatch({ type: 'SET_NEIGHBORHOOD', value: undefined });
  }

  const handleSearchChange = useCallback((e, data) => {
    clearTimeout(timeoutRef.current)
    if (searchCallback) searchCallback(e, { result: data.value });
    dispatch({ type: 'QUERY', query: data.value })

    timeoutRef.current = setTimeout(async () => {
      if(data.value.length === 0) {
        dispatch({ type: 'RESET' })
      }
      await searchForArea({
        searchValue:data.value,
        dispatch
      })
    }, 300)
  }, [])

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  return (
    <Search
      input={{ fluid: true }}
      icon={value ? (
        <Icon
          link
          name='times circle outline'
          style={{ position: 'absolute', right: '5px', top: '50%', transform: 'translateY(-50%)' }}
          onClick={handleClearSearch}
        />
      ) : null}
      style={{ width: '100%' }}
      loading={loading}
      placeholder='Enter address or ZIP code'
      onResultSelect={handleResultSelect}
      onSearchChange={handleSearchChange}
      results={results}
      showNoResults={loading ? false : true}
      value={value}
    />
  )
}

export default SearchBar