import {
  Modal,
  Table,
  Header,
  Grid,
  Image,
  Progress,
  Segment,
  Label,
  Loader,
  Dimmer,
  Button,
} from "semantic-ui-react";
import { getGradeColor, getGrade, priceFormatter } from "../../../util";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import {
  ViewerContext,
  ViewerDispatchContext,
} from "../../../contexts/ViewerContext";
import ZillowIcon from "../../../assets/icons/zillow.svg";
import { formatTypeToHumanFriendly } from "../../../util";

import "./PropertyViewer.scss";

const PropertyViewer = () => {
  const [loading, setLoading] = useState(false);
  const state = useContext(ViewerContext);
  const dispatch = useContext(ViewerDispatchContext);
  const { propertyOpen, propertyID, buildingData, propertyData, propertyCache, buildingCache } = state;

  const isPropertyCached = propertyCache[propertyID];

  const getPropertyData = async (propertyID) => {
    if (!propertyID) return;
    let buildingId;
    if (isPropertyCached) {
      dispatch({ type: "SET_PROPERTY_DATA", value: isPropertyCached });
      buildingId = isPropertyCached.buildingId;
    } else {
      setLoading(true);
      dispatch({ type: "SET_PROPERTY_OPEN", value: true });
      try {
        let prop = await axios.post(
          process.env.REACT_APP_API_URL + "getProperty",
          {
            id: propertyID,
          }
        );
        dispatch({ type: "SET_PROPERTY_DATA", value: prop.data });
        buildingId = prop.data.buildingId;
      } catch (error) {
        console.error("Failed to fetch property data:", error);
      } finally {
        setLoading(false);
      }
    }
    let isBuildingCached = buildingCache[buildingId];
    // Fetch building data if not already fetched, used for navigating back
    if (!buildingData && !isBuildingCached) {
      const buildingData = await axios.post(process.env.REACT_APP_API_URL + "getBuilding", {
        id: buildingId,
      });
      dispatch({ type: "SET_BUILDING_ID", value: buildingId });
      dispatch({ type: "SET_BUILDING_DATA", value: buildingData.data });
    } else if (!buildingData) {
      dispatch({ type: "SET_BUILDING_DATA", value: isBuildingCached });
    }
  };

  useEffect(() => {
    getPropertyData(propertyID);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyID, dispatch]);

  let quality_score = propertyData?.scoring?.property_score;
  let grade = getGrade(quality_score);
  return (
    propertyOpen &&
    <Modal
      onClose={() => {
        dispatch({ type: "SET_PROPERTY_OPEN", value: false });
        dispatch({ type: "SET_PROPERTY_DATA", value: null });
        dispatch({ type: "SET_BUILDING_ID", value: null });
        dispatch({ type: "SET_BUILDING_DATA", value: null });
      }}
      onOpen={() => dispatch({ type: "SET_PROPERTY_OPEN", value: true })}
      open={propertyOpen}
      className="property-viewer"
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Dimmer active inverted>
            <Loader size="large">Loading...</Loader>
          </Dimmer>
        </div>
      ) : (
        <>
          <Modal.Header>
            {!buildingData ? (
              <Button
              icon="close"
                onClick={() => {
                  dispatch({ type: "SET_PROPERTY_OPEN", value: false });
                  dispatch({ type: "SET_PROPERTY_DATA", value: null });
                }}
                className="back-button"
              >
              </Button>
            ) : (
              <Button
              icon="close"
                onClick={() => {
                  dispatch({ type: "SET_PROPERTY_OPEN", value: false });
                  dispatch({ type: "SET_PROPERTY_DATA", value: null });
                  dispatch({ type: "SET_BUILDING_OPEN", value: true });
                }}
                className="back-button"
              >
              </Button>
            )}
            <Grid padded={"horizontally"}>
              <Grid.Row verticalAlign={"middle"}>
                {propertyData?.processed?.data.address}
                <div className="zillow_icon">
                  <Image
                    src={ZillowIcon}
                    alt=""
                    href={propertyData?.processed?.data.url}
                    target="_blank"
                  />
                </div>
              </Grid.Row>
              <Grid.Row className="no-padding-top">
                <Label color={getGradeColor(grade)} size={"huge"}>
                  {grade}
                  <Label.Detail>{parseInt(quality_score * 100)}%</Label.Detail>
                </Label>
                <div className="vertical-divider"></div>
                <Label
                  className="property-viewer-label"
                  icon={"bed"}
                  size={"large"}
                  content={
                    propertyData?.processed.data.beds +
                    " bed" +
                    (propertyData?.processed.data.beds > 1 ? "s" : "")
                  }
                />
                <Label
                  className="property-viewer-label"
                  icon={"bath"}
                  size={"large"}
                  content={
                    propertyData?.processed.data.baths +
                    " bath" +
                    (propertyData?.processed.data.baths > 1 ? "s" : "")
                  }
                />
                {propertyData?.processed.data.sqft && (
                  <Label
                    className="property-viewer-label"
                    size={"large"}
                    icon={"expand"}
                    content={propertyData?.processed.data.sqft + " sqft"}
                  />
                )}
                <Label
                  className="property-viewer-label"
                  icon={"home"}
                  size={"large"}
                  content={formatTypeToHumanFriendly(
                    propertyData?.processed.data.unit_type
                  )}
                />
              </Grid.Row>
            </Grid>
          </Modal.Header>

          <Modal.Content>
          <div className="lease-table-wrapper">
          <div className="lease-table">
            <Header>Projected Leases</Header>
            <Table basic={"very"}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date Listed</Table.HeaderCell>
                  <Table.HeaderCell>Rent</Table.HeaderCell>
                  <Table.HeaderCell>Days to Lease</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {propertyData?.processed.data.leases
                  .reverse()
                  .map((lease, i) => {
                    return (
                      <Table.Row key={i}>
                        <Table.Cell>{moment(lease.lease_date).format('DD/MM/YYYY')}</Table.Cell>
                        <Table.Cell>{priceFormatter.format(lease.lease_price)}</Table.Cell>
                        <Table.Cell>{lease.days_on_market} days to lease</Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
            </div>
          </div>
            <Header>Property Images</Header>
            <Grid relaxed columns={2}>
              {propertyData?.scoring.images.map((img) => {
                if (!img.score) return <></>;
                let img_quality = Math.floor(img.score * 100);
                let grade = getGrade(img.score);

                return (
                  <Grid.Column>
                    <Segment style={{ padding: 0 }}>
                      <Image
                        src={propertyOpen ? img.url : ""}
                        style={{
                          height: 300,
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                      <Label attached="bottom left">
                        <p style={{ marginBottom: 5 }}>
                          {img.type.toUpperCase()}
                        </p>
                        <p style={{ marginBottom: 5 }}>
                          {grade + " (" + img_quality + "%) Quality Score"}
                        </p>
                        <Progress
                          style={{ marginBottom: 5 }}
                          percent={img_quality}
                          size={"tiny"}
                          color={getGradeColor(grade)}
                        />
                      </Label>
                    </Segment>
                  </Grid.Column>
                );
              })}
            </Grid>
          </Modal.Content>
        </>
      )}
    </Modal>
  );
};

export default PropertyViewer;
