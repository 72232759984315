function calculateMonthlyAverageDaysOnMarket(propertiesData) {
    let monthData = {};
    propertiesData.forEach(property => {
        const leases = property.leases;

        leases.forEach(lease => {
            let listDate = new Date(lease.lease_date);
            let month = listDate.getMonth();
            if (!monthData[month]) {
                monthData[month] = { totalDays: 0, count: 0 };
            }

            monthData[month].totalDays += lease.days_on_market;
            monthData[month].count += 1;
        });
    });

    // Calculate average days on market for each month
    for (let month in monthData) {
        monthData[month] = Math.round(monthData[month].totalDays / monthData[month].count);
    }

    return monthData;
}

export default calculateMonthlyAverageDaysOnMarket;
