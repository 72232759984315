import {useContext, useEffect, useState} from "react";
import {MapContext} from "../../../contexts/MapContext";
import {Card, Message,Icon} from "semantic-ui-react";
import InsightsIcon from "../../../assets/icons/insights.svg";
import {
  getBin,
  getCategory,
  processNeighborhoodData,
  calculateGradeCorrelation,
} from "./functions";
import { determineRatingByPercentile } from "../../../util";
import {XAxis, ReferenceLine, Bar, ResponsiveContainer, ComposedChart, Line, Cell} from "recharts";

import "./GradeSensitivityModule.scss";
// import neighborhoodData from "./sensitivity_data";


const GradeSensitivityModule = () => {
  const { data } = useContext(MapContext)
  const [ rating, setRating ] = useState()
  const [category, setCategory] = useState(getCategory(0));

  // const neighborhoodDistribution = processNeighborhoodData(neighborhoodData)
  
  useEffect(() => {
    if(!data?.properties) return
    let score = calculateGradeCorrelation(data.properties);
    setRating(determineRatingByPercentile(score));
    setCategory(getCategory(score));
  }, [data]);


  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>Grade Sensitivity</Card.Header>
        <Card.Meta>
          Rent sensitivity to property quality
        </Card.Meta>
        <Message className='insights-box'>
          <div className="icon-container">
            <img src={InsightsIcon} alt="AI Analyst" />
          </div>
          <div className='textbox-container'>
            <p>{`${category} to grade`}</p>
            {/* <p>{`${parseInt(score * 100)}%`}</p> */}
          </div>
        </Message>
        <div className={`rating-gs ${rating?.color}`}>
              {rating?.text}
        </div>

        {/* <ResponsiveContainer height={100}>
          <ComposedChart data={neighborhoodDistribution}>
          <defs>
              <linearGradient id="gradientDensity" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#D3B7E1" stopOpacity={1}/>
              <stop offset="100%" stopColor="#F5F5F5" stopOpacity={1}/>
              </linearGradient>
          </defs>
            <XAxis dataKey="bin" hide />
            <Bar dataKey="count" radius={[10, 10, 0, 0]}>
              {neighborhoodDistribution.map(entry => (
                  <Cell fill={getBin(score) == entry.bin ? '#D3B7E1' : '#d0d0d0'} />
                )
              )}
            </Bar>
            <Line
              type="monotone"
              dataKey="density"
              stroke="#D3B7E1"
              dot={false}
              strokeWidth={2}
              strokeLinecap="round"
            />
          </ComposedChart>
        </ResponsiveContainer> */}
      </Card.Content>
    </Card>
  )
}

export default GradeSensitivityModule
