import {useEffect, useState} from "react";
import axios from "axios";
import {Message, Loader} from "semantic-ui-react";
import InsightsIcon from "../../../assets/icons/insights_colored.svg";

import './AIAnalyst.scss'

const AIAnalyst = ({ data, static_data, module_name }) => {
  const [ message, setMessage ] = useState()
  const [ loading, setLoading ] = useState(false)
  const [ disabled, setDisabled ] = useState(false)

  useEffect(() => {
    if (!data || Object.keys(data).length === 0) return
    setMessage(null)
    setLoading(false)
    setDisabled(false)
  }, [ data ])

  const generate = () => {
    if(loading || disabled || !data || Object.keys(data).length === 0) return
    setLoading(true)
    axios.post(process.env.REACT_APP_API_URL + 'gpt', {
      data, static_data, module_name
    }).then(resp => {
      setMessage(resp.data)
    }).finally(() => {
      setLoading(false)
      setDisabled(true)
    })
  }

  return (
    <Message className={'ai-analyst-container'} onClick={generate}>
      <div className={`icon-container ${loading || disabled ? 'small' : ''}`}>
        <img src={InsightsIcon} alt="AI Analyst" />
        { !loading && !disabled && <span>View Analysis</span> }
      </div>
      { loading && <Loader inline size={'medium'} active={true} /> }
      {
        message && 
        <div className="message-container">
          <div className="message">
            {message}
          </div>
        </div>
      }
    </Message>
  )
}

export default AIAnalyst