import {Table, Card} from 'semantic-ui-react'
import {useContext, useState, useEffect} from 'react'
import {MapContext} from "../../../contexts/MapContext";
import calculateMonthlyAverageDaysOnMarket from "../../../util/calculateMonthlyAverageDaysOnMarket";

const LeaseByMonthTableModule = () => {
  const state = useContext(MapContext)
  const { data, } = state

  const [ leaseSummary, setLeaseSummary ] = useState()

  useEffect(() => {
    if(!data?.properties) return
    let summary = calculateMonthlyAverageDaysOnMarket(data.properties)
    console.log('summary', summary)
    setLeaseSummary(summary)
  }, [ data ])

  return !leaseSummary ? <>Loading</> : (
    <Card fluid>
      <Card.Content>
        <Card.Header>Time To Lease</Card.Header>
        <Card.Meta></Card.Meta>
        <Table definition celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
             { 
                ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov','Dec'].map((month, index) => (
                  <Table.HeaderCell key={index} textAlign={'center'}>
                    {month}
                  </Table.HeaderCell>
                ))
              }
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>Average Days</Table.Cell>
              {Array.from({ length: 12 }).map((_, index) => (
                <Table.Cell key={index} textAlign={'center'}>
                  {leaseSummary[index] ? leaseSummary[index] : '-'}
                </Table.Cell>
              ))}
            </Table.Row>
          </Table.Body>
        </Table>
      </Card.Content>
    </Card>
  )
}

export default LeaseByMonthTableModule