import getGradeFromScore from "./getGradeFromScore";

const getGradeSummary = (properties) => {
  let summary = {}

  properties.map(p => {
    p.grade = getGradeFromScore(p.property_score)
  })

  for(let prop of properties) {
    if(!summary[prop.grade]) summary[prop.grade] = {}
    let bed_key = prop.beds <= 5 ? prop.beds : 5
    if(!summary[prop.grade][prop.beds]) summary[prop.grade][prop.beds] = {
      properties: []
    }
    summary[prop.grade][prop.beds].properties.push(prop)
  }

  for(let grade in summary) {
    for(let beds in summary[grade]) {
      summary[grade][beds].average = summary[grade][beds].properties.reduce((total, prop) => {
        return total + prop.lease_price
      }, 0) / summary[grade][beds].properties.length

      summary[grade][beds].count = summary[grade][beds].properties.length
    }
  }

  return summary
}

export default getGradeSummary