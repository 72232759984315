import React, { createContext, useReducer } from "react";

export const ViewerContext = createContext(undefined);
export const ViewerDispatchContext = createContext(undefined);

const viewer_initial_state = {
  propertyOpen: false,
  propertyID: undefined,
  buildingID: undefined,
  propertyData: undefined,
  buildingOpen: false,
  buildingData: undefined,
  propertyCache: {},
  buildingCache: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PROPERTY_OPEN":
      return {
        ...state,
        propertyOpen: action.value,
        propertyID: action.value ? state.propertyID : undefined,
      };
    case "SET_PROPERTY_ID":
      return { ...state, propertyID: action.value };
    case "SET_PROPERTY_DATA":
      return {
        ...state,
        propertyData: action.value,
        propertyCache: {
          ...state.propertyCache,
          [action.value?.id]: action.value
        }
      };
    case "SET_BUILDING_OPEN":
      return {
        ...state,
        buildingOpen: action.value,
      };
    case "SET_BUILDING_DATA":
      return {
        ...state,
        buildingData: action.value,
        buildingCache: {
          ...state.buildingCache,
          [action.value?.buildingId]: action.value
        }
      };
    case "ADD_PROPERTY_TO_CACHE":
      return {
        ...state,
        propertyCache: {
          ...state.propertyCache,
          [action.value?.id]: action.value
        }
      };
    case "ADD_BUILDING_TO_CACHE":
      return {
        ...state,
        buildingCache: {
          ...state.buildingCache,
          [action.value?.buildingId]: action.value
        }
      };
    case "SET_BUILDING_ID":
      return { ...state, buildingID: action.value };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const ViewerContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, viewer_initial_state);

  return (
    <ViewerContext.Provider value={state}>
      <ViewerDispatchContext.Provider value={dispatch}>
        {children}
      </ViewerDispatchContext.Provider>
    </ViewerContext.Provider>
  );
};

export default ViewerContextProvider;
