import {Navigation} from "../../components/common/Navigation/Navigation";

import logo from '../../assets/brand/rr_logo_white.svg'

import './Landing.scss'
import {Container} from "semantic-ui-react";
import SearchForm from "../../components/home/SearchForm/SearchForm";
import Search from "../../components/landing/Search/Search";

const Landing = () => {
  return (
    <div className={'landing'}>
      <Container>
        <img className={'logo'} src={logo} />
        <div className={'content'}>
          <h1 className={'header'}>AI-Powered Residential Underwriting</h1>
          <p className={'text'}>Unlock intelligent rental pricing, proprietary indicators, and granular comparables all in real-time.</p>
          <Search />
        </div>
      </Container>
    </div>
  )
}

export default Landing