export const HEATMAP_COLORS = [
  '#DB2828',
  '#F2711C',
  '#FBBD08',
  '#B5CC18',
  '#21BA45'
]

export const VIRIDIS_COLORS = [
  '#440154',
  '#414487',
  '#2a788e',
  '#7ad151',
  '#f5c527'
]

export const getColorFromGrade = (grade) => {
  switch(grade) {
    case 'A' : return HEATMAP_COLORS[4]
    case 'B' : return HEATMAP_COLORS[3]
    case 'C' : return HEATMAP_COLORS[2]
    case 'D' : return HEATMAP_COLORS[1]
    case 'F' : return HEATMAP_COLORS[0]
    default: return HEATMAP_COLORS[0]
  }
}

export const getAttributeColors = (attribute) => {
  if(attribute == 'property_score') return HEATMAP_COLORS
  if(attribute == 'lease_psf') return VIRIDIS_COLORS
  if(attribute == 'lease_price') return VIRIDIS_COLORS
}

export const buildColorGrade = (attribute, quantiles) => {
  let colors = getAttributeColors(attribute)

  if(attribute == 'property_score') {
    return [
        colors[0],  // default color
        0.2, colors[1],
        0.4, colors[2],
        0.6, colors[3],
        0.8, colors[4],
        1, colors[4]
    ]

  }

  if(attribute == 'lease_psf' || attribute == 'lease_price') {
    return [
        0,
        colors[0],
        quantiles[0],
        colors[1],
        quantiles[1],
        colors[2],
        quantiles[2],
        colors[3],
        quantiles[3],
        colors[4],
        quantiles[4],
        colors[4]
    ]
  }
}