import axios from 'axios'
import {useEffect, useRef, useState, useContext} from "react"
import {Modal, Grid, Label} from "semantic-ui-react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import {map_initial_state, MapContext, MapDispatchContext} from "../../contexts/MapContext";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

let neighborhoodData
axios.get(process.env.REACT_APP_API_URL + 'getNeighborhoods')
  .then(resp => {
    neighborhoodData = resp.data
  })

// Check if the neighborhood is valid
export const validateNeighborhood = (data) => {
  let place_name = data.place_name;
  let neighborhood_name = place_name.substring(0, place_name.indexOf(","));

  // check if its available in the neighborhood data
  let neighborhood = neighborhoodData.features
    .find(f => f.properties.mapname === neighborhood_name);
  if(!neighborhood) return false

  return true
}

export const handleNeighborhoodSelect = ({ dispatch, mapname }) => {
  const r = getNeighborhood({mapname, dispatch})
  if(!r?.neighborhood) return false
  dispatch({ type: 'SET_NEIGHBORHOOD', value: r.neighborhood })
  return true
};

export const getNeighborhood = ({mapname, dispatch}) => {
  const neighborhood = neighborhoodData.features
    .find(f => f.properties.mapname == mapname);
  const polygons = neighborhood?.geometry.coordinates
  if(!neighborhood || !polygons) return

  let totalLat = 0;
  let totalLng = 0;
  let totalPoints = 0;

  for (const polygon of polygons) {
    for (const ring of polygon[0]) {
      totalLat += ring[1];
      totalLng += ring[0];
      totalPoints++;
    }
  }

  const centerLat = totalLat / totalPoints;
  const centerLng = totalLng / totalPoints;
  dispatch({ type: 'SET_LAT', value: centerLat })
  dispatch({ type: 'SET_LNG', value: centerLng })
  return {
    neighborhood,
    polygons,
    neighborhoodCenter: [centerLng, centerLat],
  };
}

export const handleNeighborhoodMap = ({ mapname, ogMap, dispatch }) => {
  const {neighborhoodCenter, polygons} = getNeighborhood({mapname, dispatch})
  if (neighborhoodCenter) {
    ogMap.flyTo({
      center: neighborhoodCenter,
      essential: true
    });

    let lats = polygons.flat(2).map(coord => coord[1]);
    let lngs = polygons.flat(2).map(coord => coord[0]);
    const southWest = [Math.min(...lngs), Math.min(...lats)];
    const northEast = [Math.max(...lngs), Math.max(...lats)];
    ogMap.fitBounds([southWest, northEast], {
      padding: 20,
    });
    return true
  }
}

export const NeighborhoodSelector = () => {
  const [ open, setOpen ] = useState(false)
  const neighborhoodMapContainer = useRef(null)
  const { map: ogMap, neighborhoodMap, neighborhood } = useContext(MapContext)
  const dispatch = useContext(MapDispatchContext)

  useEffect(() => {
    if(!neighborhoodMapContainer.current || !neighborhoodData) {
      return
    }
    const initializeMap = () => {
      const map = new mapboxgl.Map({
        container: neighborhoodMapContainer.current,
        style: process.env.REACT_APP_MAP_STYLE,
        center: [map_initial_state.lng, map_initial_state.lat],
        zoom: 12,
        maxZoom: 17.5,
        minZoom: 10
      })

      map.on('load', () => {
        dispatch({ type: 'SET_NEIGHBORHOOD_MAP', value: map })
        map.addSource('neighborhood-data', {
          type: 'geojson',
          data: neighborhoodData,
        });

        // Add the fill layer
        map.addLayer({
          id: 'neighborhoods-fill',
          source: 'neighborhood-data',
          type: 'fill',
          paint: {
            'fill-color': 'transparent',
          }
        });

        // Add the line layer
        map.addLayer({
          id: 'neighborhoods-line',
          source: 'neighborhood-data',
          type: 'line',
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          paint: {
            'line-color': '#fbb03b',
            'line-dasharray': [0.2, 2],
            'line-width': 3,
          }
        });

        map.addLayer({
          id: 'neighborhood-labels',
          type: 'symbol',
          source: 'neighborhood-data',
          layout: {
            'text-field': ['get', 'mapname'],
            'text-size': 14,

          },
          paint: {
            'text-halo-color': '#fff',
            'text-halo-width': 4
          }
        });

        map.addLayer({
          id: 'neighborhoods-hover',
          source: 'neighborhood-data',
          type: 'fill',
          paint: {
            'fill-color': '#000',
            'fill-opacity': 0.5
          },
          filter: ['==', 'name', '']
        });

        map.addLayer({
          id: 'selected-neighborhood',
          source: 'neighborhood-data',
          type: 'fill',
          paint: {
            'fill-color': 'red',
            'fill-opacity': 0.5
          },
          filter: ['==', 'mapname', '']
        });

        map.on('mousemove', 'neighborhoods-fill', (e) => {
          map.getCanvas().style.cursor = 'pointer'
          map.setFilter('neighborhoods-hover', ['==', 'name', e.features[0].properties.name]);
        })

        map.on('mouseleave', 'neighborhoods-fill', () => {
          map.getCanvas().style.cursor = ''
          map.setFilter('neighborhoods-hover', ['==', 'name', '']);
        })

        map.on('click', 'neighborhoods-fill', async (e) => {
          handleNeighborhoodSelect({
            mapname: e.features[0].properties.mapname,
            ogMap,
            dispatch
          })
          setOpen(false)
        })
      })
    }
    if(neighborhoodMap) {
      document.getElementById("neighborhood-map").replaceWith(neighborhoodMap.getContainer())
    } else{
      initializeMap()
    }
  })

  useEffect(() => {
    const filter = neighborhood 
      ? ['==', 'mapname', neighborhood.properties.mapname]
      : ['==', 'mapname', ''];
    neighborhoodMap?.setFilter('selected-neighborhood', filter);
  }, [neighborhood, neighborhoodMap]);

  return (
    <Modal
      onClose={() => {
        setOpen(false)
      }}
      onOpen={() => {
        setOpen(true)
      }}
      open={open}
      trigger={
        <div
        onClick={() => setOpen(true)}>
          Select Neighborhood
          {neighborhood && (
            <Label
              basic
              style={{ marginLeft: '10px', padding: '5px', backgroundColor: '#f0f0f0' }}
            >
              {neighborhood.properties.mapname}
            </Label>
          )}
        </div>
      }
    >
      <Modal.Header>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column textAlign='left' verticalAlign='middle'>
              Neighborhood Selector
              <br />
              <p style={{ fontSize: '12px', fontWeight: 'normal' }}>
                {neighborhood ? `Selected: ${neighborhood.properties.mapname}` : 'No neighborhood selected'}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <div
        id="neighborhood-map"
        ref={neighborhoodMapContainer}
        className='map-container'
        />
      </Modal.Content>
    </Modal>
  )
}