import {erf} from 'mathjs'

export const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0
})

export const priceFormatterDecimal = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2
})

export const getGrade = (s) => {
  if(s <= .2) return 'F'
  else if(s <= .4) return 'D'
  else if(s <= .6) return 'C'
  else if(s <= .8) return 'B'
  else if(s > .8) return 'A'
}

export const getGradeExtended = (s) => {
  if (s <= 0.2) return 'F';
  else if (s <= 0.267) return 'D-';
  else if (s <= 0.333) return 'D';
  else if (s <= 0.4) return 'D+';
  else if (s <= 0.467) return 'C-';
  else if (s <= 0.533) return 'C';
  else if (s <= 0.6) return 'C+';
  else if (s <= 0.667) return 'B-';
  else if (s <= 0.733) return 'B';
  else if (s <= 0.8) return 'B+';
  else if (s <= 0.867) return 'A-';
  else if (s <= 0.933) return 'A';
  else return 'A+';
}

export const getGradeColor = (g) => {
  if(g == 'A') return 'green'
  if(g == 'B') return 'olive'
  if(g == 'C') return 'yellow'
  if(g == 'D') return 'orange'
  if(g == 'F') return 'red'
}

export const getGradeColorHEX = (g) => {
  if(g == 'A') return '#21BA45'
  if(g == 'B') return '#B5CC18'
  if(g == 'C') return '#FBBD08'
  if(g == 'D') return '#F2711C'
  if(g == 'F') return '#DB2828'
}

export const ungroupProperties = (properties) => {
  if (!properties) return []
  let res = []
  for(let prop of properties) {
    if(prop.id) res.push(prop)
    else res = res.concat(prop.properties)
  }
  return res
}

export const extractLeases = (properties) => {
  let leases = []
  for(let prop of properties) {
    for(let lease of prop.leases) {
      leases.push({
        normal_score: prop.property_score,
        address: prop.address,
        beds: prop.beds,
        baths: prop.baths,
        sqft: prop.sqft,
        id: prop.id,
        psf: lease.lease_price / prop.sqft,
        x: new Date(lease.lease_date).valueOf(),
        y: lease.lease_price
      })
    }
  }
  return leases
}

export function unwrapNestedArray(arr) {
  while (Array.isArray(arr) && arr.length === 1) {
    arr = arr[0];
  }
  return arr;
}

export function formatTypeToHumanFriendly(type) {
  if (!type) return "";
  return type.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
}

export function formatNumberWithCommas(num) {
  return Number(num).toLocaleString();
}

export function formatNumber(num, decimalPlaces = 4) {
  return Number(parseFloat(num).toFixed(decimalPlaces)).toString();
}

export function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export const extractZipcode = (data) => {
  let zipcode = data.place_name.match(/\d{5}/)[0];
  return zipcode
}

export const calculateDaysToLease = (leases) => {
  return Math.floor(leases.map(d => { return d.days_on_market }).reduce((a, b) => a + b) / leases.length)
}

export const computePercentile = (zScore) => {
  return 0.5 * (1 + erf(zScore / Math.sqrt(2)));
}

export const computeZScore = ({marketData, value, valueExtractor}) => {
  let totalWeightedAvg = 0;
  let totalCount = 0;

  marketData.forEach(data => {
    const extractedValue = valueExtractor(data);
    totalWeightedAvg += extractedValue * data.count;
    totalCount += data.count;
  });

  let mean = totalWeightedAvg / totalCount;

  let variance = 0;
  marketData.forEach(data => {
    const extractedValue = valueExtractor(data);
    variance += ((extractedValue - mean) ** 2) * data.count;
  });
  variance /= totalCount;

  let standardDeviation = Math.sqrt(variance);

  return (value - mean) / standardDeviation;
}

export const determineRatingByPercentile = (percentile) => {
  if (percentile < 0.33) {
    return {
      text: 'Low',
      color: 'green'
    }
  } else if (percentile < 0.67) {
    return {
      text: 'Average',
      color: 'yellow'
    }
  } else {
    return {
      text: 'High',
      color: 'red'
    }
  }
};