import {Card,Icon} from "semantic-ui-react";

import {useContext, useEffect, useState} from "react";
import {MapContext} from "../../../contexts/MapContext";
import { formatNumber, computePercentile, computeZScore, determineRatingByPercentile } from "../../../util";
import marketData from "./on_market_data.js";
import './OnMarketRateModule.scss'

const calcOnMarketRate = (properties) => {
  const currentDate = Date.now();
  let totalPercentageOnMarket = 0;
  const ONE_YEAR_IN_MS = 365 * 24 * 60 * 60 * 1000;

  for (const property of properties) {
      const leasesWithinAYear = property.leases.filter(lease => {
          const leaseDate = new Date(lease.lease_date).getTime();
          return currentDate - leaseDate <= ONE_YEAR_IN_MS;
      });

      property.totalDaysOnMarketWithinYear = leasesWithinAYear.reduce((sum, lease) => sum + lease.days_on_market, 0);
      const percentageOnMarket = (property.totalDaysOnMarketWithinYear / 365) * 100;
      totalPercentageOnMarket += percentageOnMarket;
  }

  return totalPercentageOnMarket / properties.length;
};

const OnMarketRateModule = () => {
  const { data } = useContext(MapContext)
  const [ onMarketRate, setOnMarketRate ] = useState()
  const [ rating, setRating ] = useState()

  useEffect(() => {
    if(!data || !data.properties) return
    let rate = calcOnMarketRate(data.properties)
    setOnMarketRate(rate)
    
    const z_score = computeZScore({
      marketData,
      value: rate,
      valueExtractor: data => data.onMarketRate
    });
    const percentile = computePercentile(z_score);
    setRating(determineRatingByPercentile(percentile));
  }, [data]);
  

  return (
    <Card fluid className='on-market-rate-card'>
      <Card.Content>
        <Card.Header>Vacancy Rate</Card.Header>
        { onMarketRate &&
          <>
            <div className="content-container">
                <div className="time-metric">
                    {formatNumber(onMarketRate, 2)}%
                </div>
                <div className={`rating ${rating.color}`}>
                    {rating.text}
                </div>
            </div>
            <a href="#" className="link">
              View Supply
              <Icon name='arrow right' />
            </a>
          </>
        }

      </Card.Content>
    </Card>
  )
}

export default OnMarketRateModule