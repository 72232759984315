import { handleNeighborhoodSelect } from "../components/dashboard/NeighborhoodSelector";
import { handleZipcodeSelect } from "../components/dashboard/ZipCodeSelector";
import { extractZipcode } from "../util";

export const handleSearch = ({dispatch, data, additionalData}) => {
  if (!data) return;
  if (data.place_type == "neighborhood") {
    let place_name = data.place_name;
    let neighborhood_name = place_name.substring(0, place_name.indexOf(","));
    if (
      handleNeighborhoodSelect({
        mapname: neighborhood_name,
        ogMap: additionalData.map,
        dispatch: dispatch,
      })
    ) {
      return;
    }
  }
  if (data.place_type == "postcode") {
    let zipcodeInput = extractZipcode(data)
    if (
      handleZipcodeSelect({
        zipcodeInput: zipcodeInput,
        ogMap: additionalData.map,
        dispatch: dispatch,
      })
    ) {
      return;
    }
  }

  dispatch({ type: "SET_LNG", value: data.center[0] });
  dispatch({ type: "SET_LAT", value: data.center[1] });
  dispatch({ type: "SET_RADIUS", value: additionalData.radius });
  if (additionalData.filter) {
    dispatch({ type: "SET_FILTER", value: additionalData.filter });
  }
  
  if (additionalData.map) {
    additionalData.map.flyTo({
        center: data.center,
        essential: true,
    });
  }
};
