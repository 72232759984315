import React from 'react'
import { Menu } from 'semantic-ui-react'
import SearchIcon from '../../../assets/icons/search.svg'

import './TabMenu.scss'

const tabs = [
  { name: 'Overview', path: '/overview' },
  { name: 'Supply', path: '/supply' },
  { name: 'Demand', path: '/demand' },
  { name: 'Comps', path: '/comps' },
]

const TabMenu = ({ activeTab, onTabClick }) => {
  const handleItemClick = (e, { name }) => onTabClick(name.toLowerCase());

  return (
      <Menu pointing secondary className='tabmenu' >
        {tabs.map((tab, i) => (
          <Menu.Item
            key={i}
            name={tab.name}
            active={activeTab === tab.name.toLowerCase()}
            onClick={handleItemClick}  
            className='tabmenu__item'
          >
            {tab.name}
          </Menu.Item>
        ))}
      </Menu>
  )
}

export default TabMenu