import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../../common/SearchBar/SearchBar';
import { MapDispatchContext } from "../../../contexts/MapContext";
import { Dropdown, Grid, Message, Button, Form } from 'semantic-ui-react';
import { handleSearch } from '../../../util/handleSearch';

import './Search.scss'

const Search = () => {
  const [addressSelected, setAddressSelected] = useState(undefined);
  const [addressError, setAddressError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useContext(MapDispatchContext)

  const handleSubmit = (address) => {
    if (!addressSelected && !address) {
      setAddressError(true);
      return;
    }
    let addr = addressSelected || address
    handleSearch({
      dispatch,
      data: addr,
      additionalData: { radius: '0.25' }
    })
    navigate('/dashboard');
  };

  return (
    <Form className={'searchForm'}>
        <Form.Field error={addressError} className={'searchInput'}>
          <SearchBar
            fullWidth={true}
            searchCallback={(e, data) => {
              setAddressSelected(null);
              setAddressError(false);
            }}
            resultCallback={(e, data) => {
              setAddressSelected(data.result);
              setAddressError(false);
              handleSubmit(data.result)
            }}
          />
          {addressError && <Message error content='Address is a required field!' />}
        </Form.Field>
        <Button
          onClick={handleSubmit}
          className={'searchButton'}
        >
          ANALYZE
        </Button>
    </Form>
  );
};

export default Search;