import axios from "axios";
import { Modal, Table, Label, Dimmer, Loader, Icon, List } from "semantic-ui-react";
import { getGradeColor, getGrade } from "../../../util";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import {
  ViewerContext,
  ViewerDispatchContext,
} from "../../../contexts/ViewerContext";

import "./BuildingViewer.scss";

const BuildingViewer = () => {
  const state = useContext(ViewerContext);
  const dispatch = useContext(ViewerDispatchContext);
  const [loading, setLoading] = useState(false);
  const { buildingOpen, buildingData, buildingID, buildingCache } = state;

  const isBuildingCached = buildingCache[buildingID];

  const getBuildingData = async (buildingID) => {
    if (!buildingID) return;
    if (isBuildingCached) {
      dispatch({ type: "SET_BUILDING_DATA", value: isBuildingCached });
      return;
    }
    setLoading(true);
    dispatch({ type: "SET_BUILDING_OPEN", value: true });
    try {
      let prop = await axios.post(
        process.env.REACT_APP_API_URL + "getBuilding",
        {
          id: buildingID,
        }
      );
      dispatch({ type: "SET_BUILDING_DATA", value: prop.data });
    } catch (error) {
      console.error("Failed to fetch building data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBuildingData(buildingID);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingID, dispatch]);

  return (
    buildingOpen && (
      <Modal
        onClose={() => {
          dispatch({ type: "SET_BUILDING_OPEN", value: false });
          dispatch({ type: "SET_BUILDING_ID", value: null });
          dispatch({ type: "SET_BUILDING_DATA", value: null });
        }}
        onOpen={() => dispatch({ type: "SET_BUILDING_OPEN", value: true })}
        open={buildingOpen}
        className="building-viewer"
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Dimmer active inverted>
              <Loader size="large">Loading...</Loader>
            </Dimmer>
          </div>
        ) : (
          <>
            <Modal.Header>
              {buildingData?.rental_license?.opa ? (
                <>
                  <a
                    href={`https://atlas.phila.gov/${buildingData?.rental_license?.opa}/li`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{buildingData?.address}</span>
                    <Icon name="chain" />
                  </a>
                  <List>
                  {
                    buildingData?.rental_license?.date_issued && (
                      <List.Item>
                        <List.Icon name='calendar' />
                        <List.Content><strong>Date Issued: </strong>{moment(buildingData.rental_license.date_issued).format('DD/MM/YYYY')}</List.Content>
                      </List.Item>
                    )
                  }
                  {
                    buildingData?.rental_license?.unit_count && (
                      <List.Item>
                        <List.Icon name='hashtag' />
                        <List.Content><strong>Unit Count: </strong>{buildingData.rental_license.unit_count}</List.Content>
                      </List.Item>
                    )
                  }
                  {
                    buildingData?.rental_license?.owner_name && (
                      <List.Item>
                        <List.Icon name='user' />
                        <List.Content>
                          <strong>Owner: </strong>{buildingData.rental_license.owner_name}
                        </List.Content>
                      </List.Item>
                    )
                  }

                  </List>
                </>
              ) : (
                <span>{buildingData?.address}</span>
              )}
            </Modal.Header>
            <Modal.Content>
              <Table basic={"very"} collapsing selectable>
                <Table.Body>
                  {buildingData?.properties.map((prop, i) => {
                    return (
                      <Table.Row
                        key={i}
                        onClick={() => {
                          dispatch({ type: "SET_BUILDING_OPEN", value: false });
                          dispatch({ type: "SET_PROPERTY_ID", value: prop.id });
                          dispatch({ type: "SET_PROPERTY_OPEN", value: true });
                        }}
                      >
                        <Table.Cell>{prop.processed.data.address}</Table.Cell>
                        <Table.Cell>
                          <Label
                            circular
                            content={getGrade(prop.scoring.property_score)}
                            color={getGradeColor(
                              getGrade(prop.scoring.property_score)
                            )}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          { 
                            prop.processed.data.beds !== null &&
                            <Label
                              icon={"bed"}
                              content={
                                prop.processed.data.beds +
                                " bed" +
                                (prop.processed.data.beds > 1 ? "s" : "")
                              }
                            />
                          }
                        </Table.Cell>
                        <Table.Cell>
                          {
                            prop.processed.data.baths !== null &&
                            <Label
                              icon={"bath"}
                              content={
                                prop.processed.data.baths +
                                " bath" +
                                (prop.processed.data.baths > 1 ? "s" : "")
                              }
                            />
                          }
                        </Table.Cell>
                        <Table.Cell>
                          {!!prop.processed.data.sqft && (
                            <Label
                              content={prop.processed.data.sqft + " sqft"}
                            />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Modal.Content>
          </>
        )}
      </Modal>
    )
  );
};

export default BuildingViewer;
