import {Card, Icon} from "semantic-ui-react";
import {useContext, useEffect, useState} from "react";
import {MapContext} from "../../../contexts/MapContext";
import { computePercentile, computeZScore, determineRatingByPercentile } from "../../../util";
import marketData from "./time_on_market_data";
import './TimeOnMarketModule.scss'

const calcTimeOnMarket = ({data, marketData}) => {
  if (!data || !data.properties) return;

  // Average days on market for map data
  let leases = data.properties.flatMap(property => property.leases);
  const area_avg = leases
    .reduce((sum, lease) => sum + lease.days_on_market, 0)
    / leases.length;

  const z_score = computeZScore({
    marketData,
    value: area_avg,
    valueExtractor: data => data.avg_days_on_market
  });
  const percentile = computePercentile(z_score);

  return {
    area_avg,
    percentile,
    rating: determineRatingByPercentile(percentile)
  };
};


const TimeOnMarketModule = () => {
  const { data } = useContext(MapContext)
  const [ moduleData, setModuleData ] = useState()
  const [ rating, setRating ] = useState()

  useEffect(() => {
    const result = calcTimeOnMarket({data, marketData});
    if (result) {
      setModuleData({
        area_avg: result.area_avg,
        percentile: result.percentile
      });
      setRating(result.rating);
    }
  }, [data]);

  return (
    <Card fluid className='time-to-lease-card'>
      <Card.Content>
        <Card.Header>Time To Lease</Card.Header>
        { moduleData &&
          <>
            <div className="content-container">
                <div className="time-metric">
                    {Math.round(moduleData.area_avg)} Days
                </div>
                <div className={`rating ${rating.color}`}>
                    {rating.text}
                </div>
            </div>
            <a href="#" className="link">
              View Demand
              <Icon name='arrow right' />
            </a>
          </>
        }

      </Card.Content>
    </Card>
  )
}

export default TimeOnMarketModule;
