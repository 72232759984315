import {Modal, Button, Container, Dropdown, Input} from 'semantic-ui-react'
import React, {useContext, useState} from 'react'
import {MapContext, MapDispatchContext} from "../../../contexts/MapContext";
import './MapFilter.scss'

import BathIcon from '../../../assets/icons/toilet.svg'
import BedIcon from '../../../assets/icons/bed.svg'
import GradeIcon from '../../../assets/icons/star.svg'
import HouseIcon from '../../../assets/icons/house.svg'

const bed_options = ["0", "1", "2", "3", "4", "5+"]
const bath_options = ["1", "1.5", "2", "3", "4+"]
const grade_options = ["A", "B", "C", "D", "F"]
const property_type_options = [
  { text: 'Apartments', value: 'APARTMENT' },
  { text: 'Condos', value: 'CONDO' },
  { text: 'Houses', value: 'SINGLE_FAMILY' },
  { text: 'Townhomes', value: 'TOWNHOUSE' },
]

const MapFilter = () => {
  const state = useContext(MapContext)
  const dispatch = useContext(MapDispatchContext)

  return (
    <>
        <BedsButton state={state} dispatch={dispatch} />
        <BathsButton state={state} dispatch={dispatch} />
        <PropertyTypeButton state={state} dispatch={dispatch} />
        <GradeButton state={state} dispatch={dispatch} />
    </>
  )
}

const BedsButton = ({ state, dispatch }) => (
  <Dropdown
    button
    className={'filterButton' + (state.filter.beds ? ' active' : '')}
    text={<>
      <img src={BedIcon} />
      <span>{state.filter.beds ? state.filter.beds + " Bed" +
        (bed_options.indexOf(state.filter.beds) > 1 ? "s" : "") : "Beds"}</span>
    </>}
  >
    <Dropdown.Menu className='dropdown-menu'>
      <Dropdown.Item>
        <span>Number of Bedrooms</span>
        <Button
          className='clear-button'
          size={"tiny"}
          floated={"right"}
          icon={"close"}
          content={"Clear"}
          onClick={() => {
            dispatch({
              type: "SET_FILTER",
              value: { beds: undefined },
            });
          }}
        />
      </Dropdown.Item>
      <Dropdown.Item>
        <Button.Group basic fluid>
          {bed_options.map((e, i) => {
            return (
              <Button
                basic
                key={i}
                content={e}
                active={e == state.filter.beds}
                onClick={() => {
                  dispatch({
                    type: "SET_FILTER",
                    value: { beds: e },
                  });
                }}
              />
            );
          })}
        </Button.Group>
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
)

const BathsButton = ({ state, dispatch }) => (
  <Dropdown
    button
    className={'filterButton' + (state.filter.baths ? ' active' : '')}
    text={<>
      <img src={BathIcon} />
      <span>{state.filter.baths ? state.filter.baths + " Bath" +
        (bath_options.indexOf(state.filter.baths) > 1 ? "s" : "") : "Baths"}</span>
    </>}
  >
    <Dropdown.Menu className='dropdown-menu'>
      <Dropdown.Item>
      <span>Number of Bathrooms</span>
        <Button
          className='clear-button'
          size={"tiny"}
          floated={"right"}
          icon={"close"}
          content={"Clear"}
          onClick={() => {
            dispatch({
              type: "SET_FILTER",
              value: { baths: undefined },
            });
          }}
        />
      </Dropdown.Item>
      <Dropdown.Item>
        <Button.Group basic fluid>
          {bath_options.map((e, i) => {
            return (
              <Button
                basic
                key={i}
                content={e}
                active={e == state.filter.baths}
                onClick={() => {
                  dispatch({
                    type: "SET_FILTER",
                    value: { baths: e },
                  });
                }}
              />
            );
          })}
        </Button.Group>
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
)

const GradeButton = ({ state, dispatch }) => (
  <Dropdown
    button
    className={'filterButton' + (state.filter.grade ? ' active' : '')}
    text={<>
      <img src={GradeIcon} />
      <span>{state.filter.grade ? state.filter.grade : "Grade"}</span>
    </>}
  >
    <Dropdown.Menu direction={'left'} className='dropdown-menu'>
      <Dropdown.Item>
        <span>Property Grade</span>
        <Button
          className='clear-button'
          size={"tiny"}
          floated={"right"}
          icon={"close"}
          content={"Clear"}
          onClick={() => {
            dispatch({
              type: "SET_FILTER",
              value: { grade: undefined },
            });
          }}
        />
      </Dropdown.Item>
      <Dropdown.Item>
        <Button.Group basic fluid>
          {grade_options.map((e, i) => {
            return (
              <Button
                basic
                key={i}
                content={e}
                active={e == state.filter.grade}
                onClick={() => {
                  dispatch({
                    type: "SET_FILTER",
                    value: { grade: e },
                  });
                }}
              />
            );
          })}
        </Button.Group>
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
)

const PropertyTypeButton = ({ state, dispatch }) => (
  <Dropdown
    button
    className={'filterButton' + (state.filter.propertyType ? ' active' : '')}
    text={<>
      <img src={HouseIcon} />
      
      <span>{property_type_options.find(e => e.value == state.filter.propertyType)?.text || "Property Type"}</span>
    </>}
  >
    <Dropdown.Menu direction={'left'} className='dropdown-menu'>
      <Dropdown.Item>
        <span>Property Type</span>
        <Button
          className='clear-button'
          size={"tiny"}
          floated={"right"}
          icon={"close"}
          content={"Clear"}
          onClick={() => {
            dispatch({
              type: "SET_FILTER",
              value: { propertyType: undefined },
            });
          }}
        />
      </Dropdown.Item>
      <Dropdown.Item>
        <Button.Group basic fluid>
          {property_type_options.map((e, i) => {
            return (
              <Button
                basic
                key={i}
                content={e.text}
                active={e.value == state.filter.propertyType}
                onClick={() => {
                  dispatch({
                    type: "SET_FILTER",
                    value: { propertyType: e.value },
                  });
                }}
              />
            );
          })}
        </Button.Group>
      </Dropdown.Item>
    </Dropdown.Menu>
  </Dropdown>
)

export default MapFilter